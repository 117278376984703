<div class="container-fluid delete_page">
  <div class="row mx-2 d-flex justify-content-center">
<h3 class="modal-title mt-2 mb-2 text-center">Delete Account</h3>
</div>
<p class="text-center theme-color mb-2 delete_account">
  Are you sure you want to delete this account?
</p>

<div class="col-12 d-flex justify-content-center">
  <button type="reset" class="btn btn-outline-black mt-4 mb-2 mx-2 font-16 mw-auto" [routerLink]="['/homepage']">No</button>
<button class="btn btn-black mt-4 mb-2 mx-2 font-16  mw-auto" (click)="deleteAccount()">Yes</button>

</div>

</div>
