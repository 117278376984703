import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apiService: ApiService) {}

  //APi call for getting list of unclaimed profiles matching the profileNames.
  getProfilesByProfileName(params: HttpParams) {
    return this.apiService.get('rest/profiles/', params);
  }

  //APi call for getting the detailed profile.
  getProfileDetails(params: HttpParams) {
    return this.apiService.get('rest/profiles/profileDetails', params);
  }

  //APi call for getting the detailed profile with flags.
  getProfileV1(params: HttpParams) {
    return this.apiService.get('rest/profiles/view-profile', params);
  }

  getProfile(params: HttpParams) {
    return this.apiService.get('rest/profiles/view-profile-v2', params);
  }

  //API call for claiming a particular profile.
  claimProfile(params: HttpParams) {
    return this.apiService.putParams('rest/profiles/claim', params);
  }

  //API call for creating a particular profile.
  createProfile(formData: FormData) {
    return this.apiService.postFile('rest/profiles/', formData);
  }

  //API call for creating a particular profile.
  updateProfile(formData: FormData) {
    return this.apiService.postFile('rest/profiles/edit', formData);
  }

  //API call for uploading any file.
  uploadFile(formData: FormData) {
    return this.apiService.postFile('rest/profiles/file', formData);
  }

  //API call for getting all the profiles (irrespective  of claimed or unclaimed), matching the search string, used in homepage component.
  getAllProfilesByProfileName(params: HttpParams) {
    return this.apiService.get('rest/profiles/search', params);
  }

  //APi call for posting a review.
  postAReview(formData: FormData) {
    return this.apiService.postFile('rest/reviews/', formData);
  }

  //APi call for getting reviews posted on the profile.
  getReviewsOnProfile(params: HttpParams) {
    return this.apiService.get('rest/reviews/', params);
  }

  //APi call for getting suggestions for mentioning.-names
  getSuggestions(params: HttpParams) {
    return this.apiService.get('rest/search/tag-names', params);
  }

  //APi call for getting suggestions for search.
  getSuggestionsByName(params: HttpParams) {
    return this.apiService.get('rest/profiles/names', params);
  }

  //APi call for getting a particular.
  getReview(params: HttpParams) {
    return this.apiService.get('rest/reviews/nestedReview', params);
    //return this.apiService.get('rest/reviews/review', params);
  }

  //APi call for getting responses on particular review.
  getAllResponses(params: HttpParams) {
    return this.apiService.get('rest/reviews/v1/responses', params);
    // return this.apiService.get('rest/reviews/responses', params);
  }

  //APi for getting trending reviews.
  getTrendingReviews() {
    return this.apiService.get('rest/reviews/trending/v2');
  }

  //Api for getting trending reviews on Profile.
  getTrendingReviewsOnProfile(params: HttpParams) {
    return this.apiService.get('rest/reviews/trending/profile', params);
  }

  //Api for getting trending reviews on Profile.
  getClaimedProfiles(params: HttpParams) {
    return this.apiService.get('rest/profiles/claimed', params);
  }

  //Api for getting trending reviews on Profile.
  getCreatedProfiles(params: HttpParams) {
    return this.apiService.get('rest/profiles/created', params);
  }

  //Api for getting trending reviews on Profile.
  getProfileByTagName(params: HttpParams) {
    return this.apiService.get('rest/profiles/mention', params);
  }

  //API call for getting all the profiles (irrespective  of claimed or unclaimed), matching the search string, used in homepage component.
  searchProfiles(params: HttpParams) {
    return this.apiService.get('rest/search/profiles', params);
  }

  //API call for adding to searchHistory
  addToSearchHistory(params: HttpParams) {
    return this.apiService.postParams('rest/profiles/recent', params);
  }

  //API call for getting search history
  getRecentSearch(params: HttpParams) {
    return this.apiService.get('rest/profiles/recent', params);
  }

  //API call for adding to visited profiles
  addToVisitedProfiles(formData: FormData) {
    return this.apiService.postFile('rest/profiles/visited', formData);
  }

  //API call for getting ranked profiles i.e visited profiles first
  searchRankedProfiles(params: HttpParams) {
    return this.apiService.get('rest/search/ranked-profiles', params);
  }

  getProfileByAddress(params: HttpParams) {
    return this.apiService.get('rest/profiles/getDetailsByAddress', params);
  }

  getReprentatives(params: HttpParams) {
    // return this.apiService.get('rest/profiles/getReprentatives', params);
    return this.apiService.get('rest/profiles/reprentativeV1', params);
  }

  getOfficeName(params: HttpParams) {
    return this.apiService.get('rest/profiles/getOfficeByProfileId', params);
  }

  getReviewLevel(params: HttpParams) {
    return this.apiService.get('rest/reviews/level/v2', params);
  }

  getOfficeLevel() {
    return this.apiService.get('rest/profiles/officeLevels');
  }

  getOfficeRole() {
    return this.apiService.get('rest/profiles/officeRoles');
  }

  getOfficeByCity(params: HttpParams) {
    return this.apiService.get('rest/profiles/officeName', params);
  }

  getOfficeCountry() {
    return this.apiService.get('rest/profiles/country');
  }

  getOfficeState(params: HttpParams) {
    return this.apiService.get('rest/profiles/state', params);
  }

  getOfficeCity(params: HttpParams) {
    return this.apiService.get('rest/profiles/city', params);
  }

  checkAndSaveReprentatives(body: any) {
    return this.apiService.post('rest/profiles/checkAndSavereprentative', body);
  }

  setVerbDetail(params: HttpParams) {
    return this.apiService.putParams('rest/user/userVerb', params);
  }

  getMyContribution(params: HttpParams) {
    // return this.apiService.get('rest/reviews/myContribution', params);
    return this.apiService.get('rest/reviews/V1/myContribution', params);
  }

  getNestedConvertion(params: HttpParams) {
    return this.apiService.get('rest/reviews/v1/responses', params);
  }

  getNestedReview(params: HttpParams) {
    return this.apiService.get('rest/reviews/nestedReview', params);
  }

  //APi call for getting responses on particular review.
  getAllResponsesV2(params: HttpParams) {
    return this.apiService.get('rest/reviews/v2/responses', params);
  }
  blockProfile(body: any) {
    return this.apiService.post('rest/profiles/block', body);
  }
  getBlockProfiles(params: HttpParams) {
    return this.apiService.get('rest/profiles/block', params);
  }
  sendReportAsSpamMail(body: any) {
    return this.apiService.post('rest/reviews/sendReportAsSpamMail', body);
  }
  fetchStateList() {
    return this.apiService.get('rest/profiles/states');
  }
  fetchElectionDates(params: HttpParams) {
    return this.apiService.get('rest/profiles/electionDates', params);
  }
  fetchCandidates(params: HttpParams) {
    return this.apiService.get('rest/profiles/candidates', params);
  }
  fetchCandidatesOverlay(params: HttpParams) {
    return this.apiService.get('rest/profiles/candidateOverlay', params);
  }
  fetchUnderReviewProfiles() {
    return this.apiService.get('rest/profiles/inReview');
  }
  updateProfileStatus(params: HttpParams) {
    return this.apiService.putParams('rest/profiles/status', params);
  }
}
