/* Ref Link for auth guard: https://codeburst.io/using-angular-route-guard-for-securing-routes-eabf5b86b4d1 */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  public path: any = null;
  public routeFlag: boolean = false;
  public roleId = 0;

  constructor(private authService: AuthService, private router: Router) {
    let currpath = this.router.getCurrentNavigation();
    if (currpath != undefined) {
      let varpath = currpath.extractedUrl.toString();
      let pathlist = varpath.split('/');
      if (pathlist.length >= 1) {
        this.path = pathlist[1];
      }
    }
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.getLogflag()) {
      var user = this.authService.getUser();
      console.log('user data ' + JSON.stringify(user));
      var rolelist = user['roleProtectedObjectList'];
      loop1: for (var i = 0; i < rolelist.length; i++) {
        var protectedObjList = rolelist[i]['protectedObjects'];
        console.log(
          'roleList ' +
            JSON.stringify(rolelist) +
            ' contains ' +
            JSON.stringify(protectedObjList)
        );
        this.roleId = rolelist[0]['roleId'];
        for (var j = 0; j < protectedObjList.length; j++) {
          var obj = protectedObjList[j];
          if (this.path.toString() == obj['protectedObjectName']) {
            this.routeFlag = true;
            break loop1;
          }
        }
      }
      if (this.routeFlag) {
        return true;
      } else {
        // navigate to home page
        if (this.roleId == 2) {
          this.router.navigate(['/homepage']); // default page for user
          this.path = 'homepage';
        } else if (this.roleId == 1) {
          this.router.navigate(['/category']); // default page for Admin
          this.path = 'category';
        }
        return false;
      }
    } else {
      // navigate to login page
      this.router.navigate(['/login']);
      return false;
    }
  }
}
