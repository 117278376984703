import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { User } from '../../core/models/user.model';
import { UserService } from '../../core/services/user.service';
import { AuthService } from '../../core/services/auth.service';

import { Meta, Title } from '@angular/platform-browser';
import { DesignService } from '../../core/services/design.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
})
export class SigninComponent implements OnInit {
  // user
  user: User = new User();
  navigation: any;
  // flag denoting invalid login i.e wrong email or password.
  invalidLogin: boolean = false;
  loading = false;

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private designService: DesignService,
    private title: Title,
    private meta: Meta
  ) {
    this.designService.setHeaderStyle('auth'); // Set Header Style to auth
    this.navigation = this.router.getCurrentNavigation(); // Get Current URL

    const user = this.authService.getUser(); // Get user from session
    console.log('User', user);
    if (user !== null) {
      if (user.roleProtectedObjectList[0].roleId === 1) {
        // If the logged in user is admin
        this.router.navigate(['/category']); // navigate to category
      } else if (user.roleProtectedObjectList[0].roleId === 2) {
        // If the logged in user is not admin
        if (user.verified === false || user.verified === null) {
          // user is not verified.
          this.router.navigate(['/homepage']); // navigate to homepage
        } else if (user.verified === true) {
          // user is verified.
          if (
            (user.defaultProfileId === false &&
              user.reviewProfileId == false) ||
            user.verified === null
          ) {
            this.router.navigate(['/profile/search-profile']); // navigate to /profile/search-profile
          } else if (user.defaultProfileId !== null) {
            this.router.navigate(['/homepage']); // navigate to homepage
          }
        }
      }
    }
    this.title.setTitle('CitizeX');
    let description =
      'CitizeX is social networking service based on United states politicians where all levels of government politicians can interact with message known as "Post a Review." Verified politicians can give review and response, but unregistered politicians can only read them.';
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.removeTag('name= "keywords"');
  }

  ngOnInit() {}

  // takes the login credentials as input and passes it to service method.
  // used for logging in to the account
  validateLogin(signinForm: NgForm) {
    if (signinForm.invalid) {
      return;
    }
    this.loading = true;
    this.user.userName = signinForm.form.value.userName.toLowerCase();
    this.user.email = signinForm.form.value.userName.toLowerCase();
    this.user.password = signinForm.form.value.password;
    this.userService.login(this.user).subscribe(
      (success) => {
        /* if (success.success === 1) {
          this.invalidLogin = true;
        } else */
        if (success.success === 0) {
          this.authService.setToken(success.token);
          this.invalidLogin = false;
          this.loading = false;
          // SetLogflag for Authenticate user
          this.authService.SetLogFlag();
          this.authService.setSessionData(success.data);
          // When remMe is true then set cookies for next 7 days in application
          if (signinForm.form.value.remMe === true) {
            this.authService.setCookie(success.data);
          }
          if (success.data.roleProtectedObjectList[0].roleId === 1) {
            // If the logged in user is admin
            this.router.navigate(['/category']);
          } else if (success.data.roleProtectedObjectList[0].roleId === 2) {
            // If the logged in user is not admin
            if (
              success.data.verified === false ||
              success.data.verified === null
            ) {
              // user is not verified.
              this.router.navigate(['/signup']);
            } else if (success.data.verified === true) {
              // user is verified.
              if (
                success.data.defaultProfileId === null &&
                success.data.reviewProfileId == null
              ) {
                this.router.navigate(['/profile/search-profile']);
              } else if (
                success.data.defaultProfileId !== null ||
                success.data.reviewProfileId != null
              ) {
                this.router.navigate(['/homepage']);
              }
            }
          }
        } else {
          this.invalidLogin = true;
        }
      },
      (error) => {
        this.invalidLogin = true;
        console.log('Error While Login', error);
      }
    );
  }
}
