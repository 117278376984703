<app-loader></app-loader>
<div class="container-fluid m-0 p-0 h-100">
  <!--Pre-logIn-->
  <div
    class="row m-0"
    [ngClass]="{
      layout: headerStyle !== 'auth',
      'layout-auth': headerStyle === 'auth'
    }"
  >
    <!-- If not auth module -->
    <app-header
      class="layout-header"
      *ngIf="headerStyle !== 'auth'"
    ></app-header>
    <!-- If auth module -->
    <app-sidebar
      class="auth-sidebar"
      *ngIf="headerStyle === 'auth'"
    ></app-sidebar>

    <!-- Content -->
    <section
      [ngClass]="{
        'auth-content': headerStyle === 'auth',
        'layout-content': headerStyle !== 'auth'
      }"
    >
      <router-outlet></router-outlet>
    </section>

    <!-- If not auth module -->
    <app-post-footer
      class="layout-footer"
      *ngIf="headerStyle !== 'auth'"
    ></app-post-footer>
    <!-- If auth module -->
    <app-footer class="auth-footer" *ngIf="headerStyle === 'auth'"></app-footer>
  </div>
</div>
