export enum ElementTypes {
  Textbox = 'Textbox',
  RadioButton = 'Radio Button',
  Checkbox = 'Checkbox',
  DatePicker = 'Date Picker',
  Email = 'Email',
  Dropdown = 'Dropdown',
  MultivalueTextbox = 'Multivalue Textbox',
  keekuUsername = 'CitizeX Username',
  textArea = 'Textarea',
}

export enum DataType {
  String = 'String',
  Datetime = 'Datetime',
  Integer = 'Integer',
  Double = 'Double',
}

export enum RequiredValues {
  Yes = 'Yes',
  No = 'No',
}

export enum ReviewMediaTypes {
  imageType2 = 'image/jpe',
  image = 'image/jpeg',
  imageType3 = 'image/png',
  gif = 'image/gif',
  video = 'video/mp4',
  videoType2 = 'video/webm',
  videoType3 = 'video/mkv',
  videoType4 = 'video/mov',
  videoType5 = 'video/avi',
  videoType6 = 'video/mpeg',
  videoType7 = 'video/mpeg-4',
  videoType8 = 'video/wav',
  videoType9 = 'video/m4a',
  videoType10 = 'video/wmv',
  videoType11 = 'video/3gpp',
  videoType12 = 'video/3gp',
  videoType13 = 'video/x-matroska',
  videoType14 = 'video/quicktime',
}
