import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../core';
import { AuthService } from '../../core/services/auth.service';
import { DesignService } from '../../core/services/design.service';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss'],
})
export class DeleteAccountComponent implements OnInit {
  public getUser() {
    return this.authService.getUser();
  }
  user = this.getUser();

  constructor(
    private designService: DesignService,
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {
    this.designService.setHeaderStyle('privacyAndPolicy');
  }

  ngOnInit() {
    this.user = this.authService.getUser();
  }
  deleteAccount() {
    const params = new HttpParams().set(
      'userId',
      Number(this.user.userId).toString()
    );
    this.userService.deleteUserAccount(params).subscribe((res) => {
      const resData = res;
      console.log('Delete Account API Response:' + resData);
      if (resData.success === 0) {
        this.logOut();
      }
    });
  }
  logOut() {
    // Remove logIn flag in authservice
    this.authService.removeLogFlag();
    // Remove cookies when logOut
    this.authService.removeCookie();
    //close connection of websocket
    // this.websocketService._disconnect();
    // Navigate to Url
    this.router.navigateByUrl('/login');
  }
}
