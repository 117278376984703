import { Component, OnInit } from '@angular/core';
import { DesignService } from '../../core/services/design.service';

@Component({
  selector: 'app-privacy-and-policy',
  templateUrl: './privacy-and-policy.component.html',
  styleUrls: ['./privacy-and-policy.component.scss'],
})
export class PrivacyAndPolicyComponent implements OnInit {
  constructor(private designService: DesignService) {
    this.designService.setHeaderStyle('privacyAndPolicy');
  }

  ngOnInit() {}
}
