<div class="container-fluid">
  <div class="row mx-2">
    <h1 class="col-12 fw-700 mt-3">Terms and Conditions</h1>
    <h5 class="col-12 fw-700 my-2 mt-3"><u> END USER LICENSE AGREEMENT </u></h5>
    <p class="col-12 my-2 text-justify">
      The following END USER LICENSE AGREEMENT (the “EULA”) governs the use of
      Jikia (“we,” “our,” or “us”) websites, located at www.citizex.info, or
      participating in any online features, services and/or programs oﬀered by
      us (collectively, the “Website”). This EULA is in eﬀect for all of our
      Websites.
    </p>
    <p class="col-12 my-2 text-justify fw-700">
      BY ACCESSING OR USING OUR WEBSITES, YOU ARE ENTERING INTO A LEGAL CONTRACT
      WITH JIKIA REGARDING YOUR USE OF THE WEBSITES AND YOU AGREE TO BE BOUND BY
      THIS EULA. IF YOU DO NOT AGREE TO ANY PORTION OF THIS EULA, YOU SHOULD NOT
      ACCESS OR OTHERWISE USE THE WEBSITE.
    </p>
    <p class="col-12 my-2 text-justify fw-700">
      YOU SHOULD CAREFULLY REVIEW THIS ENTIRE EULA. INCLUDED IN THIS LEGAL
      CONTRACT ARE (i) REQUIREMENTS THAT YOU PROVIDE TRUTHFUL, ACCURATE, CURRENT
      AND COMPLETE INFORMATION ABOUT YOUR IDENTITY; (ii) PROHIBITIONS ON
      ALLOWING ANYONE OR ANY ENTITY OTHER THAN YOURSELF USE OF YOUR USER
      ACCOUNT; AND (iii) TERMS CLEARLY INDICATING YOU ARE RESPONSIBLE FOR ANY
      LEGAL ISSUES CAUSED BY YOUR CONTENT OR YOUR USE OF THE WEBSITES.
    </p>
  </div>
  <div class="row mx-2">
    <h5 class="col-12 fw-700 mt-3"><u>TABLE OF CONTENTS</u></h5>
    <div class="col-auto"></div>
    <div class="col my-2">
      <ul>
        <li class="my-2">CONVENIENCE AND INFORMATION ONLY</li>
        <li class="my-2">USER ACCOUNT</li>
        <li class="my-2">ACCURATE AND TRUTHFUL INFORMATION</li>
        <li class="my-2">ACCOUNT DEACTIVATION</li>
        <li class="my-2">OBJECTIONABLE MATERIA</li>
        <li>CONTENT</li>
        <li class="my-2">OUR PARTICIPATION</li>
        <li class="my-2">
          NO WARRANTIES, LIMITATIONS OF LIABILITY, YOUR INDEMNIFICATION OF US
        </li>
        <li class="my-2">JURISDICTION AND VENUE</li>
        <li class="my-2">MISCELLANEOUS</li>
      </ul>
    </div>
  </div>
  <div class="row mx-2 terms-list-style">
    <ul class="col mx-2">
      <li>
        <span class="fw-700">CONVENIENCE AND INFORMATION ONLY.</span>
        The Websites are provided to you as a convenience and for your
        informaKon only. By merely providing access to the Website, we do
        <span class="fw-700"><u> NOT</u></span> guarantee, warrant or represent
        that:
      </li>
      <div class="col my-2">
        <ol>
          <li class="my-2">
            Any materials, statements, reviews, ratings, opinions, documents,
            images, graphics, logos, designs, audio, video, comments and any
            other information provided from or on the Websites (collectively,
            the “Content”) is accurate or complete;
          </li>
          <li class="my-2">The Content is up-to-date or current;</li>
          <li class="my-2">We have any obligaKon to update any Content;</li>
          <li class="my-2">
            We will keep your Content confidential or restrict third parties
            from any use of your Content;
          </li>
          <li class="my-2">
            Any use, copy, reproduction, adaptation, publication, performance,
            or audio recording of the Content is free from allegations, claims,
            or lawsuits, including those based on allegations of intellectual
            property infringement, libel or slanderous defamatory statements,
            and/or violations of the right of publicity, including personality
            rights, the use of one's identity, such as name, image, likeness, or
            other unequivocal identifiers;
          </li>
          <li class="my-2">
            The Content is free from changes caused by a third party;
          </li>
          <li class="my-2">
            Your access to the Website will be free from interruptions, errors,
            viruses or other harmful components;
          </li>
          <li class="my-2">No data breach will ever occur; and/or</li>
          <li class="my-2">
            Information transmitted through the Internet is secure, or that such
            transmissions are free from delay, interruption, interception or
            error.
          </li>
        </ol>
      </div>
      <li class="my-2">
        <span class="fw-700">USER ACCOUNT.</span>
        To access the Website, we require you to select a username and password,
        and create a user account (“User Account”). User Accounts are unique to
        an individual real person. You are prohibited from creating or using a
        User Account on behalf of an entity, organization, group, non-profit,
        university or other educational institution, political organization,
        government or governmental agency or entity, etc. In your User Account
        information, you may designate or be required to designate affiliation
        with a such group; provided however, that use of your User Account is
        limited to you as an individual. The following shall apply to your User
        Account:
      </li>
      <div class="col my-2 list-style">
        <ol>
          <li class="my-2">
            We use reasonable precautions to protect the privacy of your
            username, password, and User Account information including your User
            Identity (defined in §3a below). You, however, are ultimately
            responsible for protecting your username, password, and User Account
            information from disclosure to third parties;
          </li>
          <li class="my-2">
            You are not permitted to circumvent the use of required encryption
            technologies, if any;
          </li>
          <li class="my-2">
            You agree to (i) immediately notify us of any unauthorized use of
            your username, password, or User Account, or any other breach of
            security, and (ii) ensure that you exit from your User Account at
            the end of each session;
          </li>
          <li class="my-2">
            Usernames and passwords are non-transferable, and all users are
            obligated to take preventative measures to prohibit any other person
            or entity from accessing the Websites with his or her username,
            password, or other account information
          </li>
        </ol>
      </div>
      <li class="my-2">
        <span class="fw-700">ACCURATE AND TRUTHFUL INFORMATION.</span>
        In creaKng and using your User Account for use on the Website, you agree
        to:
      </li>
      <div class="col my-2 list-style">
        <ol>
          <li class="my-2">
            Provide true, accurate, current, and complete informaKon about
            yourself (“User IdenKty”); and
          </li>
          <li class="my-2">
            Maintain and promptly update your User IdenKty to keep it true,
            accurate, current, and complete.
          </li>
          <li class="my-2">
            You agree that we may collect and use data and related informaKon,
            including but not limited to demographical informaKon, poliKcal
            informaKon, technical informaKon about your device, system and
            applicaKon solware, and peripherals, and that we may use this
            informaKon, as long as it is in a form that does not personally
            idenKfy you.
          </li>
        </ol>
      </div>
      <li class="my-2">
        <span class="fw-700">ACCOUNT DEACTIVATION.</span>
        We reserve the right to, suspend, deacKvate, terminate or cancel your
        User Account, and/or remove some or all of any Content associated in any
        way with your User Account in our sole discreKon, including for the
        following reasons:
      </li>
      <div class="col my-2 list-style">
        <ol>
          <li class="my-2">
            You provide any informaKon that is untrue, inaccurate, not current,
            or incomplete, or if we have reasonable grounds to suspect that such
            informaKon is untrue, inaccurate, not current, or incomplete;
          </li>
          <li class="my-2">You request such deacKvaKon;</li>
          <li class="my-2">You are deceased;</li>
          <li class="my-2">
            You do not respond to repeated communicaKon agempts;
          </li>
          <li class="my-2">
            You reside in or relocate to a country where use of a User Account
            is prohibited under applicable law;
          </li>
          <li class="my-2">
            You allow any other person or enKty to access the Websites using
            your username and password, and/or
          </li>
          <li class="my-2">
            You act in a fraudulent or an inappropriate manner while using the
            User Account.
          </li>
        </ol>
      </div>
      <li class="my-2">
        <span class="fw-700">OBJECTIONABLE MATERIAL.</span>
        You acknowledge that in using the Websites and accessing the Content,
        you may encounter material that you may deem to be inaccurate, harmful,
        biased, disturbing, offensive or objecKonable. You agree to use the
        Websites at your sole risk and that we shall have no liability to you
        for any such material.
      </li>
      <li class="fw-700 my-2">CONTENT</li>
      <div class="col my-2 list-style">
        <ol>
          <li class="my-2">
            TO PROTECT YOUR PRIVACY AND THE PRIVACY OF OTHERS, YOU WILL NOT
            PROVIDE ANY USER CONTENT THAT CONTAINS PERSONALLY IDENTIFIABLE
            INFORMATION (SUCH AS NAME, PHONE NUMBER, EMAIL OR MAILING ADDRESS,
            SOCIAL SECURITY NUMBER, ETC.) BELONGING TO ANYONE ELSE
          </li>
          <li class="my-2">
            UPLOADING IMAGES OR VIDEO OF OTHER PEOPLE WITHOUT THEIR EXPRESS
            WRITTEN PERMISSION IS STRICTLY PROHIBITED.
          </li>
          <li class="my-2">
            You are solely responsible for your Content, and the consequences of
            posKng or publishing it
          </li>
          <li class="my-2">
            You represent and warrant that: (i) you own or have the necessary
            licenses, rights, consents, and permissions to use, and to authorize
            us and other users of the Websites to publish your Content; and (ii)
            to the extent applicable, you have the wrigen consent, release,
            and/or permission of each idenKfiable individual person in your
            Content to use the name or likeness of each such person to enable
            inclusion and publicaKon of the Content.
          </li>
          <li class="my-2">
            By providing Content to us, you hereby grant us a worldwide,
            non-exclusive, royalty-free, irrevocable, sublicenseable, and
            transferable license to use, reproduce, distribute, prepare
            derivaKve works of, display, publish, republish, and perform your
            Content, including, but not limited to, publicaKon of any porKon or
            all of your Content in an adverKsement, a book, a magazine, a spoken
            word work, any other literary work, and/or any other audio-visual
            work, in any medium or format, anywhere in the world.
          </li>
          <li class="my-2">
            You acknowledge that we reserve the right to pre-screen your Content
            and that we can in our sole discreKon refuse, move, and/or remove
            any Content for any reason.
          </li>
          <li class="my-2">
            You agree to not use the Website to: (i) upload, post, email,
            transmit or otherwise make available any Content that is unlawful,
            harmful, threatening, abusive, harassing, bullying, torKous, false,
            defamatory, vulgar, obscene, pornographic, sexually explicit,
            libelous, invasive of another’s privacy, hateful, or racially,
            ethnically or otherwise objecKonable; (ii) harm minors in any way;
            (iii) impersonate any person or enKty, including, but not limited
            to, an official or an employee of Jikia; (iv) falsely state or
            otherwise misrepresent your affiliaKon with any person or enKty; (v)
            forge headers or otherwise manipulate idenKfiers in order to
            disguise the origin of any Content; (vi) upload, post, email,
            transmit, or otherwise make available any unsolicited or
            unauthorized adverKsing, promoKonal materials, spam, phishing
            schemes, pyramid schemes, or any other form of solicitaKon; (vii)
            upload, post, email, transmit, or otherwise make available any
            material that contains solware that may harm the operaKon of the
            Websites and their delivery of the Content to users; and/or (viii)
            intenKonally or unintenKonally violate any applicable local, state,
            naKonal, or internaKonal law; (ix) stalk or otherwise harass
            another; or (x) collect or store personal data about other users.
          </li>
          <li class="my-2">
            You acknowledge and agree that we may disclose your idenKty and/or
            User idenKty in connecKon with any claim of an intellectual property
            violaKon or any other courtordered purpose
          </li>
        </ol>
      </div>
      <li class="my-2">
        <span class="fw-700">OUR PARTICIPATION.</span> We do not endorse any
        Content, or any opinion, recommendaKon, or advice expressed in the
        Content, and we expressly disclaim any and all liability in connecKon
        with Content. We do not knowingly permit copyright infringing acKviKes
        and infringement of intellectual property rights on the Websites. We
        reserve the right to remove Content without prior noKce. Without limiKng
        our right to cause the terminaKon of a User Account for any or no
        reason, we will also cause the terminaKon of a User Account if a user is
        determined to be a repeat offender, a repeat offender being a user who
        has been noKfied of inappropriate Content or behavior.
      </li>
      <li class="fw-700">
        NO WARRANTIES, LIMITATIONS OF LIABILITY, YOUR INDEMNIFICATION OF US.
      </li>
      <div class="col my-2 list-style">
        <ol>
          <li class="my-2">
            NO WARRANTIES FOR WEBSITES. We make no warranty concerning the
            delay, failure, interrupKon, or corrupKon of any data, the Content,
            or other informaKon transmiged in connecKon with the use of the
            Websites. YOU EXPRESSLY AGREE THAT YOUR USE OF THE WEBSITES IS AT
            YOUR SOLE RISK. THE WEBSITES, THE CONTENT IS PROVIDED “AS IS” AND
            “AS AVAILABLE” FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EITHER
            EXPRESS OR IMPLIED, UNLESS SUCH WARRANTIES ARE LEGALLY INCAPABLE OF
            EXCLUSION. WE MAKE NO REPRESENTATIONS OR WARRANTIES THAT THE
            WEBSITES, THE CONTENT, OR ANY SERVICES OFFERED IN CONNECTION WITH
            THE WEBSITES, ARE OR WILL REMAIN UNINTERRUPTED OR ERROR-FREE, THAT
            DEFECTS WILL BE CORRECTED, OR THAT THE WEB PAGES ON OR THROUGH THE
            WEBSITES, OR THE SERVERS USED IN CONNECTION WITH THE WEBSITES, ARE
            OR WILL REMAIN FREE FROM ANY VIRUSES, WORMS, TIME BOMBS, DROP DEAD
            DEVICES, TROJAN HORSES, OR OTHER HARMFUL COMPONENTS. WE DO NOT
            GUARANTEE THAT YOU WILL BE ABLE TO ACCESS OR USE THE WEBSITES AT
            TIMES OR LOCATIONS OF YOUR CHOOSING, OR THAT WE WILL HAVE ADEQUATE
            CAPACITY FOR THE WEBSITES AS A WHOLE OR IN ANY SPECIFIC GEOGRAPHIC
            AREA. WE MAKE NO REPRESENTATION OR WARRANTY REGARDING GOVERNMENT
            COMPLIANCE OF ANY SOFTWARE USED IN RUNNING THE WEBSITES. OUR ENTIRE
            LIABILITY AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE USE OF ANY
            SERVICE OR PRODUCT PROVIDED ON OR THROUGH THE WEBSITES WILL BE THE
            REFUND OF MONIES PAID BY YOU TO US, IF ANY.
          </li>
          <li class="my-2">
            LIMITATION OF LIABILITY. TO THE EXTENT NOT PROHIBITED BY LAW, IN NO
            EVENT WILL WE BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
            SPECIAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES ARISING FROM
            YOUR USE OF OR INABILITY TO USE THE WEBSITES AND/OR ANY CONTENT
            PROVIDED IN CONNECTION WITH THE WEBSITES OR FOR ANY OTHER CLAIM
            RELATED IN ANY WAY TO YOUR USE OF THE WEBSITES AND/OR ANY CONTENT.
            ADDITIONALLY, WE SHALL NOT BE LIABLE FOR NEGATIVE REPERCUSSIONS TO
            ANY PARTY BASED ON THE USE OF OR INABILITY TO USE THE WEBSITES,
            INCLUDING BUT NOT LIMITED TO LOST GOODWILL OR LOST PROFITS. WE SHALL
            BE LIABLE ONLY TO THE EXTENT OF ACTUAL DAMAGES INCURRED BY YOU, NOT
            TO EXCEED THE AMOUNT YOU ACTUALLY PAID TO US FOR GOODS OR SERVICES
            IN THE PRIOR SIX (6) MONTHS, IF ANY. WE ARE NOT LIABLE FOR ANY
            PERSONAL INJURY, INCLUDING DEATH, OR PROPERTY DAMAGE CAUSED BY YOUR
            USE OR MISUSE OF THE WEBSITES, THE CONTENT AND/OR THE USER CONTENT.
            REMEDIES UNDER THESE EULA ARE EXCLUSIVE AND ARE LIMITED TO THOSE
            EXPRESSLY PROVIDED FOR IN THESE EULA. BECAUSE SOME STATES OR
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
            FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
            JURISDICTIONS OUR LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT
            PERMITTED BY APPLICABLE LAW
          </li>
          <li class="my-2">
            INDEMNIFICATION. You agree to defend, indemnify, and hold Jikia
            harmless and our directors, officers, employees, and agents from and
            against any and all claims, demands, suits, proceedings, liabiliKes,
            judgments, losses, damages, expenses, and costs (including without
            limitaKon reasonable agorneys’ fees) assessed or incurred by us,
            directly or indirectly, with respect to or arising out of: (i) your
            failure to comply with these EULA; (ii) your breach of your
            obligaKons under these EULA; (iii) your use of Websites including
            your Content; and/or (iv) any claim that your Content caused damage
            to a third party.
          </li>
        </ol>
      </div>
      <li class="my-2">
        <span class="fw-700">JURISDICTION AND VENUE.</span> You agree that the
        Websites shall be deemed to be solely based in California, and that the
        Websites shall be deemed a passive website that does not give rise to
        personal jurisdicKon over Jikia in jurisdicKons other than California.
        These EULA shall be governed by and construed according to the laws of
        the State of California, without reference to its choice of law
        principles. Any claim or cause of acKon arising out of or relaKng to the
        Websites shall be brought in the state or federal courts located in the
        Central District of California and you hereby agree to the jurisdicKon
        and venue of those courts for any such dispute. Any such claim or cause
        of acKon shall be barred permanently unless such claim or cause of acKon
        is brought within one year aler it arises. If (i) you are not a U.S.
        ciKzen; (ii) you do not reside in the U.S.; (iii) you are not accessing
        the Service from the U.S.; or (iv) you are a ciKzen of one of the
        countries idenKfied below, you hereby agree that any dispute or claim
        arising from this Agreement shall be governed by the applicable law set
        forth below, without regard to any conflict of law provisions, and you
        hereby irrevocably submit to the non-exclusive jurisdicKon of the courts
        located in the state, province or country idenKfied below whose law
        governs: If you are a ciKzen of any European Union country or
        Switzerland, Norway or Iceland, the governing law and forum shall be the
        laws and courts of your usual place of residence.
      </li>
      <li class="my-2 fw-700">MISCELLANEOUS</li>
      <div class="col my-2 list-style">
        <ol>
          <li class="my-2">
            InterpretaKon of EULA. Where possible, these EULA shall be
            interpreted in such manner as to be effecKve and valid under
            applicable law. Should any part of these EULA for any reason be
            declared invalid or void, such decision shall not affect the
            remaining porKon which will remain in full force and effect.
          </li>
          <li class="my-2 mb-3">
            Entrie Agreement. This is the enKre agreement between us relaKng to
            your use of the Website and supersedes any prior understandings or
            agreements, wrigen or oral. These EULA may not be modified by you,
            in wriKng or otherwise, unless agreed to in a wrigen document signed
            by Jikia.
          </li>
        </ol>
      </div>
    </ul>
  </div>
</div>
