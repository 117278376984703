import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { ApiService } from './api.service';
@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private apiService: ApiService) {}

  //api to get notification count using profileId.
  getNotificationCountByProfileId(params: HttpParams) {
    return this.apiService.get('rest/reviews/notification/getNotificationCount', params);
  }
  //api to get notification By profileId.
  getNotificationsByProfileId(params: HttpParams) {
    return this.apiService.get('rest/reviews/notification/getNotification', params);
  }
  //api to update readFlag when click on notification by NotificationId.
  updateNotificationByNotificationId(params: HttpParams) {
    return this.apiService.putParams('rest/reviews/notification/updateReadFlag', params);
  }
}
