import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(private apiService: ApiService) {}

  //For hitting the API for getting the details of category.
  fetchCategoryDetails() {
    return this.apiService.get('rest/template/');
  }

  //for API call of deleting the category.
  deleteCategory(params: HttpParams) {
    return this.apiService.delete('rest/template/', params);
  }

  //for API call for updating Category.
  updateCategory(formData: FormData) {
    return this.apiService.postFile('rest/template/update', formData);
  }

  //For APi call of adding the category.
  addCategory(formData: FormData) {
    return this.apiService.postFile('rest/template/', formData);
  }

  //for APi call of fetching the subcategory details.
  fetchSubcategoryDetails(params: HttpParams) {
    return this.apiService.get('rest/template/subcategories', params);
  }

  //for API call for deleting the subcategory.
  deleteSubCategory(params: HttpParams) {
    return this.apiService.delete('rest/template/', params);
  }

  //for API call of  updating the subcategory.
  updateSubCategory(formData: FormData) {
    return this.apiService.postFile('rest/template/updateSubcategories', formData);
  }

  //for API call of adding the subcategory.
  addSubCategory(formData: FormData) {
    return this.apiService.postFile('rest/template/subcategories', formData);
  }

  //for API call of fetching the sections details of either category/subcategory.
  fetchSectionDetails(params: HttpParams) {
    return this.apiService.get('rest/template/sections', params);
  }

  //for API call of deleting a section.
  deleteSection(params: HttpParams) {
    return this.apiService.delete('rest/template/sections', params);
  }

  //For API call of updating a section.
  editSection(request: object) {
    return this.apiService.put('rest/template/sections', request);
  }

  //For API call of retrieving the field details.
  fetchFieldDetails(params: HttpParams) {
    return this.apiService.get('rest/template/section', params);
  }

  //for API call of deleting the field.
  deleteField(params: HttpParams) {
    return this.apiService.delete('rest/template/fields', params);
  }

  //for API call of updating a field.
  editField(request: object) {
    return this.apiService.put('rest/template/fields', request);
  }

  //for API call of adding a section.
  addSection(request: object) {
    return this.apiService.post('rest/template/sections', request);
  }

  //For APi call of adding a field.
  addField(request: object) {
    return this.apiService.post('rest/template/fields', request);
  }
  getSpam(){
    return this.apiService.get('rest/reviews/spam');
  }
  updateSpamStatus(params: HttpParams){
    return this.apiService.postParams('rest/reviews/spam/status', params);
  }
}
