import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'shorten' })
export class ShortenPipe implements PipeTransform {
  transform(
    input: string,
    length?: number,
    suffix?: string,
    wordBreak?: boolean
  ): string;
  transform(
    input: any,
    length?: number,
    suffix?: string,
    wordBreak?: boolean
  ): any;

  transform(
    text: any,
    length: number = 0,
    suffix: string = '',
    wordBreak: boolean = true
  ): string {
    if (!this.isString(text)) {
      return text;
    }

    let str = this.removeTags(text);

    if (str.length > length) {
      if (wordBreak) {
        return str.slice(0, length) + suffix;
      }

      // tslint:disable-next-line:no-bitwise
      if (!!~str.indexOf(' ', length)) {
        return str.slice(0, str.indexOf(' ', length)) + suffix;
      }
    }

    return text;
  }
  isString(value: any) {
    return typeof value === 'string';
  }

  removeTags(str: any) {
    if (str === null || str === '') return false;
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  }
}
