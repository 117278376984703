import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DesignService {
  headerStyle: string;
  footerStyle: string;
  public showCreatePopup: boolean = false;

  headerStyleChange: Subject<string> = new Subject<string>();
  footerStyleChange: Subject<string> = new Subject<string>();

  private subject = new Subject<any>();

  constructor() {
    this.headerStyle = 'homepage';
    this.footerStyle = 'homepage';
  }

  sendClickEvent() {
    this.subject.next('Button Clicked');
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  getHeaderStyle() {
    console.log('header style = ' + this.headerStyle);
    return this.headerStyle;
  }

  getFooterStyle() {
    console.log('header style = ' + this.footerStyle);
    return this.footerStyle;
  }

  setHeaderStyle(type: any) {
    if (this.headerStyle !== type) {
      this.headerStyle = type;
      this.headerStyleChange.next(this.headerStyle);
      this.setFooterStyle(type);
    }
  }

  setFooterStyle(type: any) {
    if (this.footerStyle !== type) {
      this.footerStyle = type;
      this.footerStyleChange.next(this.footerStyle);
    }
  }
}
