import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeolocationBlockerService {
  constructor() {
    this.blockGeolocation();
  }

  private blockGeolocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition = function(successCallback, errorCallback, options) {
        if (errorCallback) {
        console.log("Disable location failed");
        }
      };

      navigator.geolocation.watchPosition = function(successCallback, errorCallback, options) {
        if (errorCallback) {
            console.log("Disable location failed");

        }
        return 0;
      };
    }
  }
}
