import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable()
export class LoaderService {
  isLoading = new Subject<boolean>();
  disable = false;

  // show loader
  show() {
    this.isLoading.next(true);
  }

  // hide loader
  hide() {
    this.isLoading.next(false);
  }
}
