// Used as Data Transfer object for transferring details of Fields
export class Field {
  fieldId: number;
  title: string;
  datatype: string;
  maxLength: number;
  required: string;
  displayOrder: number;
  elementType: string;
  requiredText: string;
  multivalued: string;
  multiselect: string;
  content: string[];
  markAsShow: boolean;
  value?: any;
  maxlength?: number;
  hintText: string;
  visible: boolean;
  disabled: boolean;

  constructor() {
    this.fieldId = 0;
    this.title = '';
    this.datatype = '';
    this.maxLength = 0;
    this.required = '';
    this.displayOrder = 0;
    this.elementType = '';
    this.requiredText = 'No';
    this.multivalued = '';
    this.multiselect = '';
    this.content = [];
    this.markAsShow = false;
    this.hintText = '';
    this.visible = false;
    this.disabled = false;
  }
}
