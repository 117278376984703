import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../core';
import { HttpParams } from '@angular/common/http';
import { DesignService } from '../../core/services/design.service';
declare var $: any;

@Component({
  selector: 'app-reser-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private designService: DesignService
  ) {
    this.designService.setHeaderStyle('auth'); // Set Header Style to auth
  }

  userId: any;
  passwordToken: string = '';
  newPassword: string = ''; // new password string.
  confirmPwd: string = ''; // confirm password string.
  match: boolean = true;
  passwordReset: boolean = true;
  passwordMeter = false;
  errorMsg: string = '';

  ngOnInit() {
    this.userId = Number(this.route.snapshot.paramMap.get('id'));
  }

  // This method will match the typed password and confirmed password.
  confirmPassword() {
    if (
      this.newPassword == this.confirmPwd &&
      this.newPassword != null &&
      this.confirmPwd != null
    ) {
      this.match = true;
    } else if (
      this.newPassword != this.confirmPwd &&
      this.newPassword != null &&
      this.confirmPwd != null
    ) {
      this.match = false;
    }
  }

  // This method will call the change password API. It will first check the similarity of password and confirm password.
  // If the match is true, It will change the pasword.
  changePwd(invalid: any) {
    this.confirmPassword();
    if (invalid || this.match == false) {
      return;
    }

    var params = new HttpParams()
      .set('userId', this.userId.toString())
      .set('newPassword', this.newPassword)
      .set('forgotPasswordToken', this.userId.toString());

    // API call
    this.userService.resetPwd(params).subscribe((res) => {
      if (res.success == 0) {
        this.passwordReset = true; // passord rest succesfull routing to thank you page.
        this.router.navigateByUrl('/thankyou', {
          state: { passwordReset: this.passwordReset },
        });
      } else {
        this.passwordReset = false;
        this.errorMsg = res.message;
      }
    });
  }
}
