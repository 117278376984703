import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { LoaderService } from '../loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService) {}

  count = 0;
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      req.url.includes('/rest/search/tag-names') ||
      req.url.includes('/rest/profiles/candidates') ||
      req.url.includes('/rest/reviews/v2/responses') ||
      req.url.includes('/rest/reviews/v1/responses')
    ) {
      this.loaderService.hide();
    } else if (this.loaderService.disable === false) {
      this.count++;
      this.loaderService.show();
      console.log('Loader Count', this.count);
    }

    return next.handle(req).pipe(
      finalize(() => {
        if (this.count > 0) {
          this.count--;
        }
        if (this.count === 0) {
          console.log('loader hide');
          this.loaderService.hide();
        }
        this.loaderService.disable = false;
      })
    );
  }
}
