import { Component, OnInit } from '@angular/core';
import { DesignService } from '../../core/services/design.service';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  constructor(private designService: DesignService) {
    this.designService.setHeaderStyle('privacyAndPolicy');
  }

  ngOnInit() {}
}
