<div class="profile-content h-100 center">
  <div class="container px-0 scrollbar-adjust px-sm-2 px-md-4 py-3 py-md-4">
    <div class="row m-0 py-2 center">
      <div
        class="col-12 col-sm-8 col-md-6 col-lg-5 mx-auto p-4 profile-container"
      >
        <h3 class="text-center title mb-2">Update User Details</h3>
        <form
          class="row m-0 square-form needs-validation pl-md-3 pb-2"
          action="activation.html"
          (ngSubmit)="(f.form.valid)"
          #f="ngForm"
          novalidate
        >
          <div
            class="col-md-12 my-2 alert alert-danger"
            *ngIf="f.submitted && (f.untouched || f.invalid)"
          >
            Please fill up all the details correctly.
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- .alert-dangers -->

          <div
            class="col-md-12 my-2 alert alert-danger"
            *ngIf="invalidSignUp == true"
          >
            {{ errMsg }}
            <button
              type="button"
              (click)="invalidSignUp = false"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span>&times;</span>
            </button>
          </div>

          <div
            class="col-md-12 my-2 alert alert-danger"
            *ngIf="this.phoneExist == true"
          >
            This phone number is already verified. Please use different number.
            <button
              type="button"
              (click)="this.phoneExist = false"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span>&times;</span>
            </button>
          </div>

          <!-- .alert-dangers -->
          <div class="col-md-6 p-0 pr-md-3">
            <div class="form-group">
              <input
                type="text"
                [ngClass]="{ 'form-control': true, 'has-value': !!fname.value }"
                id="firstName"
                name="firstname"
                #fname="ngModel"
                [(ngModel)]="user.firstName"
                tabindex="1"
                required
              />
              <label for="firstName">First Name*</label>
              <div
                class="invalid-feedback"
                *ngIf="f.submitted && fname.invalid"
              >
                <span *ngIf="fname.errors?.['required']">
                  Please enter Firstname.</span
                >
                <span *ngIf="fname.errors?.['pattern']">
                  Please enter Valid Firstname.</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-6 p-0 pr-md-3">
            <div class="form-group">
              <input
                type="text"
                [ngClass]="{ 'form-control': true, 'has-value': !!lname.value }"
                id="lastname"
                name="lastname"
                #lname="ngModel"
                [(ngModel)]="user.lastName"
                tabindex="2"
                required
              />
              <label for="lastname">Last Name*</label>
              <div
                class="invalid-feedback"
                *ngIf="f.submitted && lname.invalid"
              >
                <span *ngIf="lname.errors?.['required']">
                  Please enter Lastname.</span
                >
                <span *ngIf="lname.errors?.['pattern']">
                  Please enter Valid Lastname.</span
                >
              </div>
            </div>
          </div>

          <!-- <div class="col-md-12 p-0 pr-md-3 align-self-start">
            <div class="form-group">
              <div class="right-icon"></div>
              <ejs-datepicker
                #DOB
                id="DOB"
                name="DOB"
                #dob="ngModel"
                placeholder="MM-DD-YYYY"
                [(ngModel)]="user.dateOfBirth"
                [max]="maxDate"
                format="MM-dd-yyyy"
                start="Decade"
                depth="Month"
                strictMode="true"
                aria-placeholder="YYYY-MM-DD"
                placeholder="YYYY-MM-DD"
                [htmlAttributes]="{ inputmode: 'none' }"
                [ngClass]="{ 'form-control': true, 'has-value': !!dob.value }"
                (focus)="$event.model.show()"
                (change)="checkDOB($event)"
                required
              ></ejs-datepicker>
              <label for="DOB">Date of birth*</label>

              <div class="invalid-feedback" *ngIf="f.submitted && dob.invalid">
                <span *ngIf="dob.errors.required"> Please enter Date Of Birth.</span>
              </div>
              <div class="invalid-feedback" *ngIf="this.validAge == false">
                <span *ngIf="this.validAge == false">Your age should be 18+</span>
              </div>
            </div>
          </div> -->
          <!-- .col -->

          <div class="col-md-12 p-0 pr-md-3">
            <div class="form-group">
              <input
                type="text"
                [ngClass]="{
                  'form-control': true,
                  'has-value': !!emailId.value
                }"
                id="emailID"
                name="emailID"
                pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
                #emailId="ngModel"
                [(ngModel)]="user.email"
                tabindex="5"
                required
                disabled
              />
              <label for="emailID">Email Address* </label>
              <div
                class="invalid-feedback"
                *ngIf="f.submitted && emailId.invalid"
              >
                <span *ngIf="emailId.errors?.['required']">
                  Please enter email address.
                </span>
                <span *ngIf="emailId.errors?.['pattern']">
                  Please enter a valid email address.
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-12 pt-md-3 pt-4 pl-0 text-center text-md-right">
            <input
              type="submit"
              class="btn btn-black w-100"
              (click)="userUpdate(f.invalid)"
              value="Update"
              tabindex="7"
            />
          </div>
          <!-- .col -->
        </form>
      </div>
      <!-- .col-md-12 -->
    </div>
  </div>
</div>
