import { NotificationService } from './services/notification.service';
import { WebsocketService } from './services/websocket.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { UserService, ApiService } from './services';
import { AuthService } from './services/auth.service';
import { LoaderService } from './loader.service';

import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { usernameValidatorDirective } from './validation/matchUsername.validator';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs-v2';

@NgModule({
  declarations: [usernameValidatorDirective],
  imports: [CommonModule, HttpClientModule, NgxLinkifyjsModule.forRoot()],
  providers: [
    UserService,
    ApiService,
    AuthService,
    LoaderService,
    WebsocketService,
    NotificationService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  exports: [usernameValidatorDirective],
})
export class CoreModule {}
