import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../core';
import { DesignService } from '../../core/services/design.service';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss'],
})
export class UnsubscribeComponent implements OnInit {
  constructor(
    private designService: DesignService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router
  ) {
    this.designService.setHeaderStyle('privacyAndPolicy');
  }

  public userId: number = 0;
  public userUnsubscribed: boolean = false;

  ngOnInit() {
    this.userId = Number(this.route.snapshot.paramMap.get('userId'));
    this.fetchUserMailSubscription();
  }

  unsubscribeMail() {
    const params = new HttpParams().set(
      'userId',
      Number(this.userId).toString()
    );
    this.userService.unsubscribeMail(params).subscribe((res: any) => {
      const resData = res;
      console.log('unsubscribeMail API Response:' + resData);
      if (resData.success === 0) {
        const currentUrl = this.router.url;
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router.navigate([currentUrl]);
          });
      }
    });
  }

  fetchUserMailSubscription() {
    const params = new HttpParams().set(
      'userId',
      Number(this.userId).toString()
    );
    this.userService.fetchUserMailSubscription(params).subscribe((res: any) => {
      const resData = res;
      console.log('fetchUserMailSubscription API Response:' + resData);
      if (resData.success === 0) {
        console.log('User is subscribed');
      } else {
        this.userUnsubscribed = true;
      }
    });
  }
}
