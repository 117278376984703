import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[NoLeadingWhitespace]',
})
export class NoLeadingWhitespaceValidatorDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (this.el.nativeElement.selectionStart === 0 && event.key === ' ') {
      event.preventDefault();
    }
  }
}
