<div class="container-fluid">
  <div class="row mx-2">
    <h1 class="col-12 fw-700 mt-3">Privacy Policy</h1>
    <p class="col-12 my-3">
      This Privacy Policy describes what personal information we collect through
      our web and mobile apps (“Platform”), how we collect the information and
      for what purpose. By using or accessing our Platform, including any
      embedded applications made available on third-party sites, you are
      consenting to the practices described in this Privacy Policy.
    </p>
    <h4 class="col-12 fw-700">INFORMATION WE COLLECT</h4>
    <p class="col-12 text-justify my-2">
      <u class="fw-700">Information Provided By You.</u> We collect and store
      personal information you provide when you fill out a form, register or
      create an account on our Platform, including your name, email address,
      phone number, personal ID for social media accounts or any other personal
      information you choose to share with us. We also collect and store
      information related to your activities and transactions on our Platform,
      including content you select, create, submit, save or post such as and
      your related posts and comments.
    </p>
    <p class="col-12 text-justify my-2">
      <u class="fw-700">Information Collected Automatically.</u> When you access
      our Platform, we may automatically collect and store information about
      your device such as your IP address, device type, device configuration,
      geographic location data, unique device identifier, requested and
      referring URLs. We may also automatically collect and store information
      about the referring page that linked you to us, your browsing actions and
      patterns while using our Platform, such as pages you viewed, date and time
      of your visit, the content you interacted with, links you clicked and
      other actions taken.
    </p>
    <p class="col-12 text-justify my-2">
      <u class="fw-700"
        >Cookies and Similar Tracking Technologies. We may use tracking
        technologies</u
      >
      such as cookies on our Platform to recognize you, better understand your
      preferences, customize and improve your user experience, and send you
      targeted advertisements. Cookies are small data files that are sent to
      your browser or related software from a web server and stored on your
      computer’s hard drive. Cookies track where you travel on our Platform and
      what you look at and select. They may store your preferences, the
      information in your file, and/or your username and/or password. A cookie
      may enable us to relate your use of our Platform to other information
      about you, including your personal information. Most web browsers can be
      set to inform you when a cookie has been sent to you and provide you with
      the opportunity to refuse that cookie. However, refusing a cookie may, in
      some cases, preclude you from using, or negatively impact the display or
      function of, the Platform or certain areas or features of the Platform.
    </p>
    <p class="col-12 text-justify my-2">
      <u class="fw-700">Do Not Track Disclosure</u>, Some browsers offer you a
      “Do Not Track” option that lets you opt-out from online behavioral
      tracking done by various companies, such as Google, AdWords or AdRoll. We
      currently do not process or respond to “Do Not Track”
      signals._____________________________
    </p>
  </div>
  <div class="row mx-2">
    <h4 class="col-12 fw-700 mt-2">HOW DO WE USE THE INFORMATION WE COLLECT</h4>
    <p class="col-12 text-justify my-2">
      We may use the information we collect to communicate and respond to your
      inquiries and requests to us, maintain our internal record keeping,
      improve user experience in using our Platform, aggregate the data for
      market data and other business intelligence reports, track aggregate use.
      In addition, we may send you notices (for example, in the form of e-mails,
      mailings, and the like), and otherwise correspond with you, about
      services, companies and events, sponsored by us and others, that we think
      might interest you. You may opt out of receiving such notices from us by
      following the instructions in the right to opt in and opt out section
      above. We may analyze user behavior as a measure of interest in, and use
      of, our Platform, both on an individual basis and in the aggregate.
    </p>
  </div>
  <div class="row mx-2">
    <h4 class="col-12 fw-700 mt-2">
      THIRD PARTIES WITH WHOM WE SHARE YOUR INFORMATION
    </h4>
    <p class="col-12 text-justify my-2">
      We may share information collected from you, including personal
      information, with the following types of third parties and for the
      following purposes.
    </p>
  </div>
  <div class="row mx-2">
    <div class="col-auto"></div>
    <div class="col text-justify list">
      <ul>
        <li>
          We may use third-party partners to help operate our Platform and may
          share your information with our affiliates, service providers and
          other third parties that provide products or services for or through
          this Platform or for our business (such as website or database hosting
          companies, address list hosting companies, e-mail service providers,
          analytics companies, and other similar service providers that use such
          information on our behalf).
        </li>
        <li class="my-2">
          Third-party service providers contracted to provide marketing and
          advertising services on our behalf. For example, when you visit our
          Platform, third-party service provider, such as AdRoll, may place
          cookies on your browser for targeted advertising purposes.
        </li>
        <li class="my-2">
          In certain situations, we may be required to disclose your personal
          information in response to lawful requests by public authorities. We
          reserve the right to disclose your information when we have a good
          faith belief that such disclosure is required or permitted by law
          pursuant to a legal request, such as a court order or other statutory
          requirement. We may also share your personal information to (a)
          cooperate with law enforcement agencies concerning conduct or activity
          that we reasonably and in good faith believe may violate domestic or
          foreign laws; (b) exercise or defend legal claims; (c) enforce or
          apply our Terms of Use and other agreements; or (d) protect the
          rights, property, or safety of our business, our products and
          services, our users or others.
        </li>
        <li class="my-2">
          In the event of a merger, acquisition, bankruptcy, or other
          transaction in which a third party assumes control of all or part of
          our business, we may share personal information from or about you to
          such third party.
        </li>
      </ul>
    </div>
  </div>
  <div class="row mx-2">
    <h4 class="col-12 fw-700 mt-2">RIGHT TO OPT-IN AND OPT-OUT</h4>
    <p class="col-12 text-justify my-2">
      You may have the right to “opt in” and “opt out” of certain of our uses of
      your personal information. For example, at the time you are requested to
      provide personal information on this Platform, you may have the
      opportunity to elect to, or not to receive correspondence from us. You may
      opt out of any communication with us by clicking the opt-out link in our
      communications, or by sending an email to info&#64;citizex.ai stating that
      you would like to be removed from a specific list, or all communications.
    </p>
    <p class="col-12 text-justify my-2">
      By providing personal information to this website, you understand and
      consent to the collection, maintenance, processing and transfer of such
      information in and to the United States and other countries and
      territories.
    </p>
  </div>
  <div class="row mx-2">
    <h4 class="col-12 fw-700 mt-2">DATA SECURITY</h4>
    <p class="col-12 text-justify my-2">
      We have implemented and maintain reasonable security procedures and
      practices, and commercially reasonable technical and organizational
      measures, to ensure a level of security appropriate to the risk, to help
      protect your personal information from unauthorized access and
      exfiltration, theft, or disclosure. However, no security system is
      perfect. We will notify you if there is a breach of our security where
      required by law or deemed necessary. You consent to our contacting you to
      provide such a notification.
    </p>
    <p class="col-12 text-justify my-2">
      If you contact us by e-mail or a “contact us” or similar feature on our
      Platform, you should be aware that your transmission might not be secure.
      A third party could view information you send by these methods in transit.
      Do not send sensitive information (such as payment card information,
      social security numbers, employer identification numbers, passwords, or
      pin numbers) to us via e-mail or via a “contact us” or similar feature on
      our Platform. We will have no liability for disclosure of your information
      due to errors or unauthorized acts of third parties during or after
      transmission
    </p>
  </div>
  <div class="row mx-2">
    <h4 class="col-12 fw-700 mt-2">CHILDREN</h4>
    <p class="col-12 text-justify my-2">
      Our Platform is not intended for or directed at children under the age of
      16. In addition, we do not knowingly collect personal information from
      children under the age of 16. If a parent or guardian becomes aware that
      his or her child has provided us information without their consent, please
      contact us, as indicated in the “Contact Us” section below. Please include
      an indication that your request is related to a Children’s Online Privacy
      Protection Act inquiry. We will delete such information from our records
      within a reasonable time.
    </p>
  </div>
  <div class="row mx-2">
    <h4 class="col-12 fw-700">THIRD-PARTY WEBSITES</h4>
    <p class="col-12 text-justify my-2">
      When you are on our Platform you may have the opportunity to visit,
      access, or link to other websites or electronic media not operated by us,
      such as websites in which our Platform is embedded. These sites may
      collect personal information about you. Such links do not constitute an
      endorsement by us of those other websites, the content displayed therein,
      or the persons or entities associated therewith. We do not control sites
      that are operated by these entities and is not responsible for the
      information practices of the other websites. The privacy policies of any
      linked websites are the sole responsibility of the other websites.
    </p>
  </div>
  <div class="row mx-2">
    <h4 class="col-12 fw-700 mt-2">CONTACT US</h4>
    <p class="col-12 text-justify my-2">
      If you have any questions regarding this Privacy Policy, please contact us
      by any of the following means:
    </p>
    <p class="col-12 text-justify">By Email: info&#64;citizex.ai</p>
  </div>
  <div class="row mx-2">
    <h4 class="col-12 fw-700 mt-2">PRIVACY POLICY UPDATES</h4>
    <p class="col-12 text-justify my-2">
      We may update this Privacy Policy to reflect changes to our practices. If
      we make a material change to this Privacy Policy, we will post the amended
      version on the Platform and other places we deem appropriate so that you
      are aware of what information we collect, how we use it, and under what
      circumstances, if any, we disclose it. This Privacy Policy is subject to
      change from time to time, so we encourage you to periodically review this
      page for updates.
    </p>
  </div>
  <div class="row mx-2">
    <h4 class="col-12 fw-700 mt-2">EFFECTIVE DATE</h4>
    <p class="col-12 text-justify my-2 mb-3">
      The effective date of this Privacy Policy is Jan 1st, 2024.
    </p>
  </div>
</div>
