import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[blankValidation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: blankValidatorDirective,
      multi: true,
    },
  ],
})
export class blankValidatorDirective implements Validator {
  @Input('blankValidation') req: string | undefined;

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (this.req === 'Yes' || (this.req !== 'Yes' && this.req !== 'No')) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { whitespace: true };
    }

    return null;
  }
}
