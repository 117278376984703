import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[maxValidation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: maxValidatorDirective,
      multi: true,
    },
  ],
})
export class maxValidatorDirective implements Validator {
  @Input('maxValidation') maxSize: string = '';

  validate(control: AbstractControl): { [key: string]: any } | null {
    const fieldValue = control.value;

    if (fieldValue != null) {
      return fieldValue.length > this.maxSize ? { maxAllow: true } : null;
    }
    return null;
  }
}
