<!-- <p class="col-md-12 col-sm-4 col-xs-5 col-5 bold font-14 pr-0">{{field.title}}</p> -->
<!-- Text Field Start -->
<ng-container [ngSwitch]="field.elementType">
  <div *ngSwitchCase="'Textbox'" class="form-group">
    <!-- var fieldID, fieldName, fieldPattern = *, fieldLabel, fieldErrorMessage -->

    <input
      [(ngModel)]="field.value"
      [name]="uniqueName"
      [id]="uniqueName"
      [ngClass]="{ 'form-control': true, 'has-value': !!text.value }"
      value=""
      #text="ngModel"
      type="text"
      [required]="field.required == 'Yes'"
      maxValidation="{{ field.maxLength }}"
      blankValidation="{{ field.required }}"
      datatypeValidation="{{ field.datatype }}"
      disabled="{{ field.disabled }}"
    />
    <label [for]="uniqueName"
      >{{ field.title }}<span *ngIf="field.required == 'Yes'">*</span></label
    >
    <span
      *ngIf="field.hintText != '' && field.hintText != null"
      tabindex="0"
      class="tooltip-cover right-icon font-16"
      data-toggle="tooltip"
      title="{{ field.hintText }}"
      tabindex="4"
    >
      <i class="fi flaticon-information"></i>
    </span>
    <div *ngIf="submitForm && text.invalid" class="invalid-feedback">
      <span *ngIf="text?.errors?.['required']">{{ field.requiredText }}</span>
      <div
        *ngIf="text?.errors?.['whitespace'] != null && !text?.errors?.['required']"
        class="invalid-feedback"
      >
        <span *ngIf="text?.errors?.['whitespace'] && field.required === 'Yes'"
          >Blank not support</span
        >
      </div>
    </div>
    <div
      *ngIf="text.invalid && text?.errors?.['maxAllow'] != null"
      class="invalid-feedback"
    >
      <span *ngIf="text?.errors?.['maxAllow']">
        Maxlength {{ field.maxLength }}
      </span>
    </div>
    <div
      *ngIf="text.invalid && text?.errors?.['numberAllow'] != null"
      class="invalid-feedback"
    >
      <span *ngIf="text?.errors?.['numberAllow']">Only Number is allow</span>
    </div>
  </div>
  <!-- .form-group -->
  <!-- Text Field Ends -->

  <!-- KeekuUsername Field Start -->
  <div *ngSwitchCase="'CitizeX Username'" class="form-group">
    <!-- var fieldID, fieldName, fieldPattern = *, fieldLabel, fieldErrorMessage -->
    <input
      [(ngModel)]="field.value"
      [name]="uniqueName"
      [id]="uniqueName"
      [ngClass]="{ 'form-control': true, 'has-value': !!catUsername.value }"
      value=""
      placeholder="@username"
      #catUsername="ngModel"
      (searchTerm)="getSuggestions($event)"
      [mentionConfig]="{
        items: items,
        labelKey: 'name',
        maxItems: 5,
        dropUp: false,
        triggerChar: '@',
        allowSpace: false
      }"
      [mentionListTemplate]="mentionListTemplate"
      type="text"
      [required]="field.required == 'Yes'"
      usernameValidation
    />
    <ng-template #mentionListTemplate let-item="item">
      <img
        *ngIf="item.img; else noImage"
        [src]="item.img"
        alt=""
        width="32"
        height="32"
        style="border-radius: 50px"
      />
      <ng-template #noImage>
        <img
          src="../../../assets/images/noprofile2.png"
          alt="No Profile"
          width="32"
          height="32"
          style="border-radius: 50px"
        />
      </ng-template>
      &nbsp;
      <span style="font-size: 16px">{{ item.name }}</span>
    </ng-template>
    <label [for]="uniqueName"
      >{{ field.title }}<span *ngIf="field.required == 'Yes'">*</span></label
    >
    <span
      *ngIf="field.hintText != '' && field.hintText != null"
      tabindex="0"
      class="tooltip-cover right-icon font-16"
      data-toggle="tooltip"
      title="{{ field.hintText }}"
      tabindex="4"
    >
      <i class="fi flaticon-information"></i>
    </span>
    <div
      *ngIf="catUsername.invalid && catUsername?.errors?.['userAllow'] != null"
      class="invalid-feedback"
    >
      <span *ngIf="catUsername?.errors?.['userAllow']">Invalid user</span>
    </div>
    <div *ngIf="submitForm && catUsername.invalid" class="invalid-feedback">
      <span *ngIf="catUsername?.errors?.['required']">{{
        field.requiredText
      }}</span>
    </div>
  </div>
  <!-- KeekuUsername Field Ends -->

  <!-- start Textarea -->
  <div *ngSwitchCase="'Textarea'" class="form-group">
    <!-- var fieldID, fieldName, fieldPattern = *, fieldLabel, fieldErrorMessage -->
    <textarea
      [(ngModel)]="field.value"
      [name]="uniqueName"
      [id]="uniqueName"
      [ngClass]="{ 'form-control': true, 'has-value': !!textArea.value }"
      value=""
      #textArea="ngModel"
      [required]="field.required == 'Yes'"
      maxValidation="{{ field.maxLength }}"
      blankValidation="{{ field.required }}"
      datatypeValidation="{{ field.datatype }}"
    ></textarea>
    <label [for]="uniqueName"
      >{{ field.title }}<span *ngIf="field.required == 'Yes'">*</span></label
    >
    <span
      *ngIf="field.hintText != '' && field.hintText != null"
      tabindex="0"
      class="tooltip-cover right-icon font-16"
      data-toggle="tooltip"
      title="{{ field.hintText }}"
      tabindex="4"
    >
      <i class="fi flaticon-information"></i>
    </span>
    <div *ngIf="submitForm && textArea.invalid" class="invalid-feedback">
      <span *ngIf="textArea?.errors?.['required']">{{
        field.requiredText
      }}</span>
      <div
        *ngIf="
          textArea?.errors?.['whitespace'] != null && !textArea?.errors?.['required']
        "
        class="invalid-feedback"
      >
        <span
          *ngIf="textArea?.errors?.['whitespace'] && field.required === 'Yes'"
          >Blank not support</span
        >
      </div>
    </div>
    <div
      *ngIf="textArea.invalid && textArea?.errors?.['maxAllow'] != null"
      class="invalid-feedback"
    >
      <span *ngIf="textArea?.errors?.['maxAllow']">
        Maxlength {{ field.maxLength }}
      </span>
    </div>
    <div
      *ngIf="textArea.invalid && textArea?.errors?.['numberAllow'] != null"
      class="invalid-feedback"
    >
      <span *ngIf="textArea?.errors?.['numberAllow']"
        >Only Number is allow</span
      >
    </div>
  </div>
  <!-- end Textarea -->

  <!-- Multivalue Textbox Field Start -->
  <div *ngSwitchCase="'Multivalue Textbox'" class="form-group">
    <!-- var fieldID, fieldName, fieldPattern = *, fieldLabel, fieldErrorMessage -->
    <div
      class="mxh-115px overflow-auto"
      [ngClass]="{
        'form-control p-0': true,
        'has-value': !!multivalueInput.value
      }"
    >
      <tag-input
        [(ngModel)]="field.value"
        [name]="uniqueName"
        [id]="uniqueName"
        #multivalueInput="ngModel"
        placeholder="+ Add Option"
        secondaryPlaceholder="+ Add Option"
        [editable]="true"
        [addOnPaste]="true"
        [pasteSplitPattern]="'-'"
        [modelAsStrings]="true"
        theme="foundation-theme"
        [required]="field.required == 'Yes'"
      ></tag-input>
    </div>
    <label [for]="uniqueName"
      >{{ field.title }}<span *ngIf="field.required == 'Yes'">*</span></label
    >
    <span
      *ngIf="field.hintText != '' && field.hintText != null"
      tabindex="0"
      class="tooltip-cover right-icon font-16"
      data-toggle="tooltip"
      title="{{ field.hintText }}"
      tabindex="4"
    >
      <i class="fi flaticon-information"></i>
    </span>
    <div *ngIf="submitForm && multivalueInput.invalid" class="invalid-feedback">
      <span *ngIf="multivalueInput?.errors?.['required']">{{
        field.requiredText
      }}</span>
    </div>
    <div
      *ngIf="submitForm && multivalueInput.value != null"
      class="invalid-feedback"
    >
      <span *ngIf="multivalueInput.value.length > field.maxLength"
        >Maxlength {{ field.maxLength }} validateion</span
      >
    </div>
  </div>
  <!-- .form-group -->
  <!-- Multivalue Textbox Field Ends -->

  <!-- multiselect checkbox-->
  <div *ngSwitchCase="'Checkbox'" class="form-group">
    <ejs-multiselect
      [(ngModel)]="field.value"
      [name]="uniqueName"
      [id]="uniqueName"
      [dataSource]="field.content"
      [ngClass]="{
        'form-control': true,
        'has-value': field.value?.length > 0 && !!field.value
      }"
      #multiselectcheckbox="ngModel"
      [required]="field.required == 'Yes'"
    ></ejs-multiselect>
    <label [for]="uniqueName"
      >{{ field.title }}<span *ngIf="field.required == 'Yes'">*</span></label
    >
    <span
      *ngIf="field.hintText != '' && field.hintText != null"
      tabindex="0"
      class="tooltip-cover right-icon font-16"
      data-toggle="tooltip"
      title="{{ field.hintText }}"
      tabindex="4"
    >
      <i class="fi flaticon-information"></i>
    </span>
    <div
      *ngIf="submitForm && multiselectcheckbox.invalid"
      class="invalid-feedback"
    >
      <span *ngIf="multiselectcheckbox?.errors?.['required']">{{
        field.requiredText
      }}</span>
    </div>
  </div>
  <!-- multiselect checkbox ends -->

  <!-- Radio Button -->
  <div *ngSwitchCase="'Radio Button'" class="form-group form-radio">
    <!-- radioboxLabel, chkName[] -->
    <label class="createProfilePadding d-block"
      >{{ field.title }}<span *ngIf="field.required == 'Yes'">*</span> :
    </label>

    <!-- loop for listArray -->
    <div
      *ngFor="let content of field.content; let i = first"
      [class]="
        submitForm && radio.invalid
          ? 'radio d-inline-block mb-0 ml-0 mr-2 radio-has-error'
          : 'radio d-inline-block mb-0 ml-0 mr-2'
      "
    >
      <label>
        <input
          [(ngModel)]="field.value"
          [name]="uniqueName"
          value="{{ content }}"
          type="radio"
          [required]="field.required == 'Yes'"
          #radio="ngModel"
        />
        <i class="helper"></i>{{ content }}
      </label>
    </div>
    <div *ngIf="submitForm" class="invalid-feedback">
      <span> {{ field.requiredText }}</span>
    </div>
    <span
      *ngIf="field.hintText != '' && field.hintText != null"
      tabindex="0"
      class="tooltip-cover right-icon font-16"
      data-toggle="tooltip"
      title="{{ field.hintText }}"
      tabindex="4"
    >
      <i class="fi flaticon-information"></i>
    </span>
    <!-- loop for listArray Ends -->
    <div class="clearfix"></div>
  </div>
  <!-- Radio Button Ends -->
  <!-- Datepicker -->
  <div *ngSwitchCase="'Date Picker'" class="form-group">
    <!-- for lable id Use Id of datepicker + '_lable' -->
    <!-- for function 'element ID' , 'ngModal Name', 'event name' -->
    <ejs-datepicker
      [(ngModel)]="field.value"
      [id]="uniqueName"
      [name]="uniqueName"
      [max]="maxDate"
      format="yyyy-MM-dd"
      start="Decade"
      depth="Month"
      strictMode="true"
      aria-placeholder="YYYY-MM-DD"
      placeholder="YYYY-MM-DD"
      [ngClass]="{ 'form-control': true, 'has-value': !!field.value }"
      (focus)="$event.model.show()"
      #datepicker="ngModel"
      [required]="field.required == 'Yes'"
      [htmlAttributes]="{ inputmode: 'none' }"
      disabled="{{ field.disabled }}"
    >
    </ejs-datepicker>
    <label [for]="uniqueName"
      >{{ field.title }} <span *ngIf="field.required == 'Yes'">*</span></label
    >
    <div *ngIf="submitForm && datepicker.invalid" class="invalid-feedback">
      <span *ngIf="datepicker?.errors?.['required']">{{
        field.requiredText
      }}</span>
    </div>
    <div class="invalid-feedback" *ngIf="this.validAge == false">
      <span *ngIf="this.validAge == false">Your age should be 18+</span>
      Hii {{ field.value }}
    </div>
  </div>
  <!-- Datepicker Ends -->

  <!-- Email Field Start -->
  <div *ngSwitchCase="'Email'" class="form-group">
    <!-- var emailID, emailName, emailPattern = *, emailLabel, emailErrorMessage -->
    <input
      [(ngModel)]="field.value"
      [name]="uniqueName"
      [id]="uniqueName"
      [ngClass]="{ 'form-control': true, 'has-value': !!emailVali.value }"
      value=""
      type="email"
      #emailVali="ngModel"
      [required]="field.required == 'Yes'"
      pattern="^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$"
      inputmode="email"
    />
    <label [for]="uniqueName"
      >{{ field.title }}<span *ngIf="field.required == 'Yes'">*</span></label
    >
    <div *ngIf="submitForm && emailVali.invalid" class="invalid-feedback">
      <span *ngIf="emailVali?.errors?.['required']">
        {{ field.requiredText }}</span
      >
      <span *ngIf="emailVali.errors?.['pattern']"> Invalid Email</span>
    </div>
  </div>
  <!-- .form-group -->
  <!-- Email Field Ends -->

  <!-- Select Field -->
  <div *ngSwitchCase="'Dropdown'" class="form-group">
    <!-- var sellectID, SelectName, selectLabel, selectErrorMessage, optionArray -->
    <div *ngIf="field.multivalued != 'Yes'" class="select">
      <select
        [ngClass]="{ 'form-control': true, 'has-value': !!field.value }"
        [(ngModel)]="field.value"
        [name]="uniqueName"
        [id]="uniqueName"
        #dropDown="ngModel"
        [required]="field.required == 'Yes'"
      >
        <option [ngValue]="null" [selected]="true" [disabled]="true">
          Select {{ field.title }}
        </option>
        <option *ngFor="let data of field.content" [value]="data">
          {{ data }} .
        </option>
      </select>
      <label [for]="uniqueName"
        >{{ field.title }}<span *ngIf="field.required == 'Yes'">*</span></label
      >
      <div *ngIf="submitForm && dropDown.invalid" class="invalid-feedback">
        <span *ngIf="dropDown?.errors?.['required']">{{
          field.requiredText
        }}</span>
      </div>
    </div>
    <div *ngIf="field.multivalued == 'Yes' && field.multiselect == 'Yes'">
      <!-- TO DO : multiselect lable-->
      <ejs-multiselect
        [(ngModel)]="field.value"
        [name]="uniqueName"
        [id]="uniqueName"
        [dataSource]="field.content"
        [ngClass]="{
          'form-control': true,
          'has-value': field.value?.length > 0 && !!field.value
        }"
        #dropDownMultiSelect="ngModel"
        [required]="field.required == 'Yes'"
      ></ejs-multiselect>
      <label [for]="uniqueName"
        >{{ field.title }}<span *ngIf="field.required == 'Yes'">*</span></label
      >
      <span
        *ngIf="field?.hintText"
        tabindex="0"
        class="tooltip-cover right-icon font-16"
        data-toggle="tooltip"
        title="{{ field.hintText }}"
        tabindex="4"
      >
        <i class="fi flaticon-information"></i>
      </span>
      <div
        *ngIf="submitForm && dropDownMultiSelect.invalid"
        class="invalid-feedback"
      >
        <span *ngIf="dropDownMultiSelect?.errors?.['required']">{{
          field.requiredText
        }}</span>
      </div>
    </div>
    <div
      *ngIf="field.multivalued == 'Yes' && field.multiselect == 'No'"
      class="select"
    >
      <select
        (change)="onChange(field.title + ',' + dropDown.value)"
        [ngClass]="{ 'form-control': true, 'has-value': !!field.value }"
        [(ngModel)]="field.value"
        [name]="uniqueName"
        [id]="uniqueName"
        #dropDown="ngModel"
        [required]="field.required == 'Yes'"
      >
        <option [ngValue]="null" [selected]="true" [disabled]="true">
          Select {{ field.title }}
        </option>
        <option *ngFor="let data of field.content" [value]="data">
          {{ data }}
        </option>
      </select>
      <label [for]="uniqueName"
        >{{ field.title }}<span *ngIf="field.required == 'Yes'">*</span></label
      >
      <div *ngIf="submitForm && dropDown.invalid" class="invalid-feedback">
        <span *ngIf="hasRequiredError(dropDown)">
          {{ field.requiredText }}
        </span>
      </div>
    </div>
  </div>
  <!-- Select Field -->
</ng-container>
