<!-- forgot password -->
<div class="content pt-md-5 m-0 signin">
  <div class="row m-0 py-2 center">
    <div class="col-12 col-sm-8 col-md-9 col-lg-7 offset-sm-2 offset-md-1">
      <h1 class="text-center text-md-left title">Forgot Password</h1>
      <p class="text-center text-md-left py-1 sub-title">
        Please enter your email address and we will send you a link to reset
        your password.
      </p>
    </div>
    <div class="col-12 col-sm-8 col-md-8 col-lg-6 offset-sm-2 offset-md-1">
      <form
        class="row m-0 square-form needs-validation"
        #forgotForm="ngForm"
        (ngSubmit)="resetPwd(forgotForm)"
        novalidate
      >
        <div
          class="col-md-12 my-2 alert alert-danger"
          *ngIf="this.invalidUser == true"
        >
          User does not exist
          <button
            type="button"
            class="close"
            (click)="this.invalidUser = false"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span>&times;</span>
          </button>
        </div>
        <!-- .alert-dangers -->

        <div class="col-md-12 my-2 alert alert-success" *ngIf="validationBox">
          We've sent you an email address with a link to reset your password and
          instructions.
          <button
            type="button"
            (click)="validationBox = false"
            class="close"
            data-dismiss="alert"
            aria-label="Close"
          >
            <span>&times;</span>
          </button>
        </div>
        <!-- .alert-dangers -->

        <div class="col-md-12 p-0">
          <div class="form-group">
            <input
              type="text"
              id="userName"
              [ngClass]="{
                'form-control': true,
                'has-value': !!userName.value
              }"
              name="userName"
              #userName="ngModel"
              ngModel
              tabindex="1"
              required
            />
            <label for="userName">Email Address</label>
            <div
              *ngIf="forgotForm.submitted && userName.invalid"
              class="invalid-feedback"
            >
              <span *ngIf="userName.errors?.['required']"
                >Email Address is required</span
              >
            </div>
          </div>
        </div>
        <!-- .col -->

        <div class="col-md-12 pt-4 text-center px-0">
          <input
            type="submit"
            *ngIf="show == false"
            [disabled]="show"
            class="btn btn-black w-100"
            value="Send"
            tabindex="2"
          />
          <div *ngIf="show">
            <p class="font-14 mt-3">
              Didn’t get password link ?
              <a
                (click)="resetPwd(forgotForm)"
                class="btn btn-link btn-link-theme font-14 p-0"
                >Resend now
              </a>
            </p>
          </div>
          <h6 class="mt-3">
            <a
              [routerLink]="['/login']"
              class="btn btn-link btn-link-theme font-14 p-0"
              tabindex="3"
              >&nbsp;Sign In</a
            >
          </h6>
        </div>
        <!-- .col -->
      </form>
    </div>
    <!-- .col-md-12 -->
  </div>
  <!-- .row -->
</div>
<!-- .content -->
<!-- forgot password Ends -->
