import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DesignService } from '../../../core/services/design.service';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-post-footer',
  templateUrl: './postLoginFooter.component.html',
  styleUrls: ['./postLoginFooter.component.scss'],
})
export class PostLoginFooterComponent implements OnInit {
  footerStyle: string;
  footerStyleSubscription: any;
  version: String = environment.version;
  date: String = environment.Date;
  constructor(
    private designService: DesignService,
    public authService: AuthService
  ) {
    this.footerStyle = designService.footerStyle;
    this.footerStyleSubscription =
      this.designService.footerStyleChange.subscribe((value) => {
        this.footerStyle = value;
      });
  }

  ngOnInit() {}
}
