import { Section } from './section.model';

// Used as Data Transfer object for transferring details of SubCategory
export class Subcategory {
  subCategoryId: number;
  name: string;
  displayOrder: number;
  sections: Section[];
  iconPath: string;
  hintText: string;

  constructor() {
    this.subCategoryId = 0;
    this.name = '';
    this.displayOrder = 0;
    this.sections = new Array<Section>();
    this.iconPath = '';
    this.hintText = '';
  }
}
