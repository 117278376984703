import { Component, OnInit } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { NgForm } from '@angular/forms';

import { UserService } from '../../core';
import { DesignService } from '../../core/services/design.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  constructor(
    private userService: UserService,
    private designService: DesignService
  ) {
    this.designService.setHeaderStyle('auth'); // Set Header Style to auth
  }
  response = Object;
  show: boolean = false;
  validationBox: boolean = false;
  invalidUser: boolean = false;
  ngOnInit() {
    this.show = false;
  }

  // used to reset password.
  resetPwd(forgotForm: NgForm) {
    if (forgotForm.invalid) {
      return;
    }

    const params = new HttpParams()
      .set('email', forgotForm.form.value.userName)
      .set('userName', forgotForm.form.value.userName);
    // API call
    this.userService.forgotPwd(params).subscribe(
      (res) => {
        res.success === 0
          ? ((this.show = true),
            (this.invalidUser = false),
            (this.validationBox = true))
          : (this.invalidUser = true);
      },
      (error) => {
        console.log('Error Connecting Forget Password API', error);
      }
    );
  }
}
