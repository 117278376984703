import { Field } from './field.model';

// Used as Data Transfer object for transferring details of Sections
export class Section {
  sectionId: number;
  name: string | null;
  displayOrder: number;
  allowMultiple: boolean;
  fields: Field[];
  subSections?: Section[];
  hintText: string;
  forAdmin: boolean;

  constructor() {
    this.sectionId = 0;
    this.name = '';
    this.displayOrder = 0;
    this.allowMultiple = false;
    this.fields = new Array<Field>();
    this.hintText = '';
    this.forAdmin = false;
    this.subSections = [];
  }
}
