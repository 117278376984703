import { Subcategory } from './subcategory.model';
import { Section } from './section.model';

// Used as Data Transfer object for transferring details of Category
export class Category {
  id: number;
  name: string;
  displayOrder: number;
  subCategories: Subcategory[];
  level: number;
  sections: Section[];
  iconPath: string;
  hintText: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.displayOrder = 0;
    this.subCategories = [];
    this.level = 0;
    this.sections = [];
    this.iconPath = '';
    this.hintText = '';
  }
}
