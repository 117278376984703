import { Pipe, PipeTransform } from '@angular/core';
import { NgxLinkifyjsService } from 'ngx-linkifyjs-v2';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'applinkifyHtml',
})
export class NgxLinkifyjsHtmlPipe implements PipeTransform {
  constructor(
    public linkifyService: NgxLinkifyjsService,
    private sanitizer: DomSanitizer
  ) {}

  transform(value: string, options?: any): SafeHtml {
    if (!value) return value;

    // Step 1: Replace mentions first
    let transformedHtml = this.replaceMentions(value);

    // Step 2: Find links in the transformedHtml and replace them
    const links = this.linkifyService.find(transformedHtml);
    links.forEach((link) => {
      // Use a safer replacement strategy with a regular expression that ensures link is replaced once
      const escapedLinkValue = this.escapeSpecialCharacters(link.value);
      transformedHtml = transformedHtml.replace(
        new RegExp(`\\b${escapedLinkValue}\\b`, 'g'),  // \b ensures whole words/links are replaced
        `<a href="${link.href}" target="_blank" rel="noopener noreferrer">${link.value}</a>`
      );
    });

    // Step 3: Return sanitized HTML
    return this.sanitizer.bypassSecurityTrustHtml(transformedHtml);
  }

  private replaceMentions(html: string): string {
    // Update the regular expression to include dots (.) and underscores (_)
    return html.replace(
      /<span class="mention" data-mention="(@[\w._]+)">(.+?)<\/span>/g, // updated regex
      (match, mention) => {
        const username = mention.substring(1); // Remove the '@' symbol
        return `<span class="mention"><a href="/profile/view-profile/${username}" class="linkify linkify-mention">${mention}</a></span>`;
      }
    );
  }

  // Helper function to escape special characters in the link value
  private escapeSpecialCharacters(value: string): string {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
