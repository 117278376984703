import { Injectable, Injector } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient, private injector: Injector) {}

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    let authService = this.injector.get(AuthService);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200',
      'Access-Control-Allow-Methods': 'GET',
      /* Authorization: `Bearer ${authService.getToken()}` */
    });
    return this.http
      .get(`${environment.serverUrl}${path}`, { headers, params })
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200',
      'Access-Control-Allow-Methods': 'PUT',
    });
    return this.http
      .put(`${environment.serverUrl}${path}`, JSON.stringify(body), { headers })
      .pipe(catchError(this.formatErrors));
  }

  putParams(path: string, params: HttpParams): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200',
      'Access-Control-Allow-Methods': 'PUT',
    });
    let postbody = {};
    return this.http
      .put(`${environment.serverUrl}${path}`, postbody, { headers, params })
      .pipe(catchError(this.formatErrors));
  }

  postFile(path: string, data: FormData): Observable<any> {
    // let headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data '  });
    return this.http
      .post(`${environment.serverUrl}${path}`, data)
      .pipe(catchError(this.formatErrors));
  }

  post(path: string, body: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200',
      'Access-Control-Allow-Methods': 'POST',
    });
    return this.http
      .post(`${environment.serverUrl}${path}`, JSON.stringify(body), {
        headers,
      })
      .pipe(catchError(this.formatErrors));
  }

  checkContent(body: any, contentType: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': contentType,
      'Ocp-Apim-Subscription-Key': '4c800313a0424d11b2a2992d7fb04862',
    });
    return this.http
      .post(
        `https://westus.api.cognitive.microsoft.com/contentmoderator/moderate/v1.0/ProcessImage/Evaluate`,
        body,
        { headers }
      )
      .pipe(catchError(this.formatErrors));
  }

  postParams(path: string, params: HttpParams): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200',
      'Access-Control-Allow-Methods': 'POST',
    });
    let postbody = {};
    return this.http
      .post(`${environment.serverUrl}${path}`, postbody, { headers, params })
      .pipe(catchError(this.formatErrors));
  }

  delete(path: string, params: HttpParams): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': 'http://localhost:4200',
      'Access-Control-Allow-Methods': 'DELETE',
    });
    return this.http
      .delete(`${environment.serverUrl}${path}`, { headers, params })
      .pipe(catchError(this.formatErrors));
  }

  checkTextContent(body: any): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'text/plain',
      'Ocp-Apim-Subscription-Key': '4c800313a0424d11b2a2992d7fb04862',
    });
    return this.http
      .post(
        `https://westus.api.cognitive.microsoft.com/contentmoderator/moderate/v1.0/ProcessText/Screen?classify=True`,
        body,
        { headers }
      )
      .pipe(catchError(this.formatErrors));
  }
}
