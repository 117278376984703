<!-- Thank you -->
<div class="content pb-md-5 m-0 signin">
  <div class="row m-0 py-2 center">
    <div class="col-12 col-sm-8 col-md-9 col-lg-7 offset-sm-2 offset-md-1">
      <h1 class="text-center text-md-left title"> Thank you </h1>
      <p class="text-center text-md-left py-1 sub-title">{{content}}</p>
      <div class="h-100px d-block">
        <h4 id="countdown" class="mt-5"></h4>
      </div>
    </div><!-- .col-md-12 -->
  </div><!-- .row -->
</div>
<!--  .container -->
<!-- Main content Ends -->
