<!-- signin -->
<div class="content pt-md-5 m-0 signin">
  <div class="row m-0 py-2 center">
    <div class="col-12 col-sm-8 col-md-9 col-lg-7 offset-sm-2 offset-md-1">
      <h1 class="text-md-left text-center">Reset Password</h1>
      <p class="text-center text-md-left py-1 sub-title">
        All new passwords must contain at least 8 characters. There must be at least one capital and one lower-case
        letter (Aa-Zz), one special symbol (#, &, % etc), and one number (0-9) in your password for the best strength.
      </p>
    </div>
    <div class="col-12 col-sm-8 col-md-8 col-lg-6 offset-sm-2 offset-md-1">
      <form class="row m-0 square-form needs-validation" action="" (ngSubmit)="(f.form.valid)" #f="ngForm" novalidate>
        <div class="col-md-12 my-2 alert alert-danger" role="alert" *ngIf="passwordReset == false">
          {{ errorMsg }}
          <button type="button" class="close" (click)="this.passwordReset = true" data-dismiss="alert"
            aria-label="Close">
            <span>&times;</span>
          </button>
        </div>

        <div class="col-md-12 p-0">
          <div class="form-group">
            <input type="password" id="newPass" name="newPass" [ngClass]="{
                'form-control': true,
                'has-value': !!newPassw.value
              }" [(ngModel)]="newPassword" #newPassw="ngModel" required minlength="8"
              pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$" tabindex="1" autocomplete="off"
              (focus)="passwordMeter = true" (focusout)="passwordMeter = false" />
            <label for="newPass">New Password*</label>
            <button *ngIf="!!newPassw.value" type="button" class="right-icon toggle-password" toggle="#newPass"
              tabindex="2">
              <i class="fi flaticon-eye"></i>
            </button>
            <password-strength-meter *ngIf="passwordMeter" class="font-16" type="password-strength-meter"
              name="passMeter" id="passMeter" [colors]="['darkred', 'orangered', 'orange', 'yellowgreen']"
              [minPasswordLength]="8" [enableFeedback]="true" [password]="newPassword"
              [(ngModel)]="newPassword"></password-strength-meter>
            <div *ngIf="f.submitted && newPassw.invalid" class="invalid-feedback">
              <span *ngIf="newPassw.errors?.['required']">Password is required</span>
              <span *ngIf="newPassw.errors?.['minlength']">Password must be at least 8 characters long.</span>
              <span *ngIf="newPassw.errors?.['pattern']">Please enter valid pasword,it must contain at least one capital
                and one lower-case letter (Aa-Zz), one special symbol (#, &, %
                etc), and one number (0-9) in your password for the best
                strength.</span>
            </div>
          </div>
        </div>
        <!-- .col -->

        <div class="col-md-12 p-0">
          <div class="form-group">
            <input type="password" id="confipassword" name="confipassword" [ngClass]="{
                'form-control': true,
                'has-value': !!confipassword.value
              }" [(ngModel)]="confirmPwd" #confipassword="ngModel" required minlength="8"
              pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$" tabindex="3"
              autocomplete="off" />
            <label for="confipassword">Confirm Password*</label>
            <button *ngIf="!!confipassword.value" type="button" class="right-icon toggle-password"
              toggle="#confipassword" tabindex="4">
              <i class="fi flaticon-eye"></i>
            </button>
            <div *ngIf="f.submitted && confipassword.invalid" class="invalid-feedback">
              <span *ngIf="confipassword.errors?.['required']">Confirm Password is required</span>
            </div>
            <span *ngIf="match == false">Password and Confirm Password doesnt match.</span>
          </div>
        </div>
        <!-- .col -->

        <div class="col-md-12 pt-md-4 pt-4 text-center px-0">
          <input type="submit" class="btn btn-black w-100" (click)="changePwd(f.invalid)" value="Save" tabindex="5" />
        </div>
        <!-- .col -->
      </form>
    </div>
    <!-- .col-md-12 -->

    <div class="modal fade" id="modal-1">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
              <span class="sr-only">Close</span>
            </button>
            <h4 class="modal-title">New password set sucessfully</h4>
          </div>
          <div class="modal-body">
            <p>Try to login with new password.</p>
          </div>
          <div class="modal-footer">
            <button type="submit" [routerLink]="['/login']" class="btn btn-black" data-dismiss="modal">
              Ok
            </button>
            <button type="reset" class="btn btn-secondary" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </div>
  <!-- .row -->
</div>
<!-- .content -->
<!-- signin Ends -->