import { Notification } from './../../../core/models/notification.model';
import { NotificationService } from './../../../core/services/notification.service';
import { WebsocketService } from './../../../core/services/websocket.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Profile } from 'src/app/core/models/profile.model';
import { AuthService } from '../../../core/services/auth.service';
import { DesignService } from 'src/app/core/services/design.service';
import { ProfileService } from 'src/app/core/services/profile.service';
import { LoaderService } from 'src/app/core/loader.service';
import { Subscription } from 'rxjs';
import { NgxLinkifyOptions } from 'src/app/core/pipes/ngx-linkifyjs.interface';
import { formatDate } from '@angular/common';
import { UserService } from 'src/app/core';
import { ReportBugFeedbackDTO } from '../../../core/models/reportBugFeedback.modal';
import { NgForm } from '@angular/forms';
import Shepherd from 'shepherd.js';

declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  headerStyle: string;
  headerStyleSubscription: any;
  searchValue = '';
  searchSuggetionList: any[] = [];
  defaultProfile = new Profile();
  public profile = new Profile();
  firstLogin: boolean = false;
  verified: boolean = false;
  nonVerifiedUser = { firstName: '', lastName: '', userName: '' };
  logoutBtn: boolean = false;
  loginBtn: boolean = false;
  clickEventsubscription: Subscription;
  notificationCount: number = 0;
  notificationList = new Array<Notification>();
  pageRequest: Number = 0;
  loading: string = 'none';
  isInAppear: boolean = false;
  welcomeMessage: boolean = false;
  todayDate: any;
  value: any;
  timestamp: number = Date.now();
  reportBugFeedbackDTO: ReportBugFeedbackDTO = new ReportBugFeedbackDTO();

  public getUser() {
    return this.authService.getUser();
  }

  user = this.getUser();

  options: NgxLinkifyOptions = {
    className: '',
    attributes(href: any, type: string) {
      type = 'linkify linkify-' + type;
      return { routerLink: href, class: type };
    },
    formatHref(href, type) {
      if (type === 'mention') {
        href = '/profile/view-profile/' + href.substring(1);
      }
      return href;
    },
    ignoreTags: ['script', 'style'],
    nl2br: true,
  };

  constructor(
    private authService: AuthService,
    private designService: DesignService,
    private profileService: ProfileService,
    private router: Router,
    private loaderService: LoaderService,
    private websocketService: WebsocketService,
    private notificationService: NotificationService,
    private userService: UserService
  ) {
    this.headerStyle = designService.headerStyle;
    this.headerStyleSubscription =
      this.designService.headerStyleChange.subscribe((value) => {
        this.headerStyle = value;
      });

    this.clickEventsubscription = this.designService
      .getClickEvent()
      .subscribe(() => {
        this.ngOnInit();
      });

    //whenever data recieved from server this will update notification count
    // this.websocketService.updateNotificationCountViaWebSocket$.subscribe((value) => {
    //  console.log('websocket data=', value);
    // let dataViaWebSocketFromServer = JSON.parse(value.toString());
    // if (dataViaWebSocketFromServer.data !== null && dataViaWebSocketFromServer.success === 0) {
    //  this.notificationCount = dataViaWebSocketFromServer.data;
    //  this.getNotifications();
    //}
    //console.log('Data recieved via web socket from server' + value);
    //console.log('Total notification count=' + this.notificationCount);
    //});
  }

  ngOnInit() {
    this.todayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
    this.user = this.authService.getUser();

    if (this.authService.getLogflag()) {
      const params = new HttpParams().set(
        'profileId',
        this.user.defaultProfileId
      ); // getting profile Id from route.
      // APi call
      this.profileService.getProfileDetails(params).subscribe((res) => {
        if (res.success === 0) {
          let temp = res.data.profileImage;
          if (
            temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'jpeg' ||
            temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'png' ||
            temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'jpg' ||
            temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'test'
          ) {
            this.defaultProfile.profileImage = temp;
          } else {
            this.defaultProfile.profileImage = '';
          }

          if (
            this.defaultProfile.profileImage.includes(
              'bioguide.congress.gov'
            ) == true
          ) {
            this.defaultProfile.profileImage = '';
          }
        }
      });
    }

    if (this.user == null || this.authService.getLogflag() == null) {
      this.logoutBtn = true;
      this.loginBtn = false;
    } else {
      this.logoutBtn = false;
      this.loginBtn = true;
    }

    if (this.user != null || this.authService.getLogflag()) {
      console.log('Here : ' + JSON.stringify(this.user));
      this.firstLogin = this.user['firstLogIn'];
      this.verified = this.user.verified;
      this.defaultProfile.profileName = this.user.defaultProfileName;
      let temp = this.user.defaultProfileImage;

      if (temp != null) {
        if (
          temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'jpeg' ||
          temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'png' ||
          temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'jpg' ||
          temp.substring(temp.lastIndexOf('.') + 1, temp.length) === 'test'
        ) {
          this.defaultProfile.profileImage = temp;
        } else {
          this.defaultProfile.profileImage = '';
        }
      }

      if (
        this.defaultProfile.profileImage.includes('bioguide.congress.gov') ==
        true
      ) {
        this.defaultProfile.profileImage = '';
      }

      this.defaultProfile.tagName = this.user.defaultProfileTagName;
      this.defaultProfile.id = this.user.defaultProfileId;
      this.nonVerifiedUser.firstName = this.user.firstName;
      this.nonVerifiedUser.lastName = this.user.lastName;
      this.nonVerifiedUser.userName = this.user.userName;

      this.pageRequest = 0;
      //this will make connection to websocket
      //  this.websocketService._connect(this.defaultProfile.id);
      this.getNotificationCount();
      //on init notification data load.
      this.getNotifications();
    }
  }

  logOut() {
    // Remove logIn flag in authservice
    this.authService.removeLogFlag();
    // Remove cookies when logOut
    this.authService.removeCookie();
    //close connection of websocket
    // this.websocketService._disconnect();
    // Navigate to Url
    this.router.navigateByUrl('/login');
  }

  // To search the profile based on the search string. Returns List of profiles matching the search string.

  headerSearchProfiles() {
    if (this.searchValue.startsWith(' ') || this.searchValue.endsWith(' ')) {
      return;
    } else {
      this.router.navigate(['/profile/view-profile/', this.searchValue]);
      this.searchValue = '';
    }
  }

  searchSelect(value: any) {
    this.searchValue = value.tagName;
    this.headerSearchProfiles();
  }
  checkLogin() {
    let user = this.authService.getUser();
    if (user != null) {
      if (user.verified) {
        if (user.defaultProfileId == null && user.reviewProfileId == null) {
          $('#restrictProfileModal').modal('show');
        } else if (user.reviewProfileId != null) {
          $('#restrictUnclaimedProfileModal').modal('show');
        } else {
          $('#postReviewModel').modal('show');
        }
      } else {
        $('#restrictModal').modal('show');
      }
    } else {
      this.router.navigateByUrl('/login');
    }
  }

  openFeedbackModal() {
    $('#haveFeedback').modal('show');
  }

  onChangeSearch(event: any) {
    if (event.startsWith(' ') || event.endsWith(' ')) {
      return;
    } else {
      this.searchValue = event.tagName;
      this.loaderService.disable = true;

      if (
        event !== undefined &&
        event !== null &&
        event !== '' &&
        event.length > 1
      ) {
        if (event.indexOf('@') === 0) {
          event = event.slice(1);
        }
        let params = new HttpParams()
          .set('tagNameSubString', event)
          .set('limit', '5'); // getting profile Id from route.

        if (this.user != null && this.user.defaultProfileId != null) {
          params = params.set(
            'profileId',
            this.user.defaultProfileId.toString()
          );
        }
        this.profileService.getSuggestions(params).subscribe(
          (success) => {
            const newList: { tagName: any; displayName: string }[] = [];
            success.data.forEach((element: any) => {
              const tag = '@' + element.tagName;

              const profileName = element.profileName;
              const displayName = `${tag}<br>${profileName}`;

              if (
                !this.searchSuggetionList.some(
                  (e) => e.tagName === element.tagName
                )
              ) {
                newList.push({ tagName: element.tagName, displayName });
              }
            });
            this.searchSuggetionList = newList;
            this.value = this.searchSuggetionList[0];
          },
          (error) => {
            console.log('Error On Search :', error);
          }
        );
      }
    }
  }

  //to get notification count
  getNotificationCount() {
    const params = new HttpParams().set(
      'profileId',
      Number(this.user.defaultProfileId).toString()
    );
    this.notificationService
      .getNotificationCountByProfileId(params)
      .subscribe((res) => {
        const resdata = res;
        if (resdata.success === 0) {
          console.log('notification count=' + resdata.data);
          this.notificationCount = resdata.data;
        }
      });
  }

  //get All notification
  getNotifications() {
    //this.notificationDataLoad = 'none';
    //this.notificationList = [];
    this.welcomeMessage = false;
    this.pageRequest = 0;
    if (this.user != null || this.authService.getLogflag()) {
      const params = new HttpParams()
        .set('profileId', Number(this.user.defaultProfileId).toString())
        .set('start', this.pageRequest.toString())
        .set('limit', '10');
      this.notificationService
        .getNotificationsByProfileId(params)
        .subscribe((res) => {
          const resData = res;
          if (resData.success === 0) {
            this.notificationList = [];
            this.notificationList = resData.data;
          } else {
            this.notificationList = [];
          }
          if (
            this.notificationList.length === 0 ||
            this.notificationList.length < 6
          ) {
            this.welcomeMessage = true;
          }
          //console.log('notifications:' + JSON.stringify(this.notificationList));
        });
    }
  }

  //update isRead Flag & notification count when click on notification
  updateNotification(notification: Notification) {
    let notifcationId = notification.id;
    let isRead = notification.isRead;
    console.log('NotificationId=' + notifcationId + '& isRead=' + isRead);
    if (!isRead) {
      const params = new HttpParams()
        .set('notificationId', Number(notifcationId).toString())
        .set('isRead', 'true');
      this.notificationService
        .updateNotificationByNotificationId(params)
        .subscribe((res) => {
          const resData = res;
          if (resData.success === 0) {
            this.notificationCount = Number(this.notificationCount) - 1;
          }
        });
    }
    console.log('updated notifi:', notification);
    this.routToNestedConversion(notification);
  }

  onAppear(event: any) {
    console.log('scroll call for notification');
    this.isInAppear = true;
    this.loading = '';
    this.pageRequest = Number(this.pageRequest) + 1;
    const params = new HttpParams()
      .set('profileId', Number(this.user.defaultProfileId).toString())
      .set('start', this.pageRequest.toString())
      .set('limit', '10');
    this.notificationService
      .getNotificationsByProfileId(params)
      .subscribe((res) => {
        const resData = res;
        if (resData.success === 0) {
          let addNotifications = new Array<Notification>();
          addNotifications = resData.data;
          if (addNotifications.length !== 0) {
            addNotifications.forEach((notification) => {
              this.notificationList.push(notification);
            });
          } else {
            this.welcomeMessage = true;
          }
          //console.log('Appended notification list=' + this.notificationList);
        }
        this.loading = 'none';
        this.isInAppear = false;
      });
  }

  @HostListener('scroll', ['$event'])
  onScroll(event: any) {
    // visible height + pixel scrolled >= total height
    //if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
    console.log('End of notification scroll');
    if (this.isInAppear === false) {
      this.onAppear(event);
    }
    //}
  }

  routToNestedConversion(notification: Notification) {
    if (notification.isReview) {
      this.router.navigateByUrl(
        '/profile/view-response/' + notification.mentionReviewId
      );
    } else {
      this.router.navigateByUrl(
        '/profile/review-response/' +
          notification.reviewId +
          '/' +
          notification.mentionReviewId
      );
    }
  }

  navigate() {
    window.location.href = '/login';
  }

  closeNavbarCollepse() {
    $('.navbar-collapse').removeClass('show');
  }

  searchOpen() {
    $('.header-search').addClass('small-screen-search');
    $('.input-container input').focus();
  }
  searchClose() {
    $('.header-search').removeClass('small-screen-search');
  }

  removeTags(str: string) {
    if (str === null || str === '') return '';
    else str = str.toString();
    return str.replace(/(<([^>]+)>)/gi, '');
  }

  openCreatePageModal() {
    $('#createPageModal').modal('show');
  }

  redirectToCreateProfilePage() {
    $('#createPageModal').modal('hide');
    this.router.navigate(['/profile/create-profile']);
  }

  deleteAccount() {
    const params = new HttpParams().set(
      'userId',
      Number(this.user.userId).toString()
    );
    this.userService.deleteUserAccount(params).subscribe((res) => {
      const resData = res;
      console.log('Delete Account API Response:' + resData);
      if (resData.success === 0) {
        $('#deleteFieldModal').modal('hide');
        this.logOut();
      }
    });
  }

  openDeleteAccountModal() {
    $('#deleteFieldModal').modal('show');
  }

  onEnter(event: Event): void {
    console.log('Enter key pressed', event);
    const keyboardEvent = event as KeyboardEvent; // Type assertion
    if (keyboardEvent.key === 'Enter') {
      // Check the key property
      if (this.value?.tagName) {
        this.router.navigate(['/profile/view-profile/', this.value.tagName]);
      } else {
        console.error('tagName is undefined');
      }
    }
  }

  aboutus() {
    $('#aboutUsModal').modal('show');
  }

  onFocus(event: FocusEvent): void {
    event.stopImmediatePropagation();
  }

  sendBugReportOrFeedback(form: NgForm) {
    if (form.invalid) {
      return;
    }
    this.reportBugFeedbackDTO.userId = this.user.userId;

    this.userService
      .sendBugReportOrFeedback(this.reportBugFeedbackDTO)
      .subscribe((res: any) => {
        const respData = res;
        if (respData.success == 0) {
          $('#haveFeedback').modal('hide');
          this.resetFormData(form);
        } else {
          $('#haveFeedback').modal('hide');
          this.resetFormData(form);
          console.log('Error while sending report bug or feedback');
        }
      });
  }

  resetFormData(form: NgForm) {
    form.resetForm();
    this.reportBugFeedbackDTO = new ReportBugFeedbackDTO();
  }
}
