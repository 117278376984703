import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { DesignService } from '../../core/services/design.service';
import { User, UserService } from '../../core';
import { AuthService } from '../../core/services/auth.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  logflag: boolean;
  navigation: any;
  invalidSignUp: boolean = false;
  errMsg: any;
  phoneExist: any;
  user: User = new User();
  validAge: boolean = true;

  constructor(
    private designService: DesignService,
    private userService: UserService,
    private router: Router,
    private authService: AuthService
  ) {
    if (!this.authService.getLogflag()) {
      this.designService.setHeaderStyle('auth'); // Set Header Style to auth
      this.logflag = false;
    } else {
      this.logflag = true;
      this.designService.setHeaderStyle('profile'); // Set Header Style to profile
    }
    this.navigation = this.router.getCurrentNavigation();
  }

  @ViewChild('DOB') public DOB: DatePickerComponent | undefined;
  public date: Object = new Date();
  public currentDate: Date = new Date();
  public newDate: Number = this.currentDate.getFullYear() - 18;
  public maxDate: Date = new Date(
    this.currentDate.getMonth() +
      1 +
      '-' +
      (this.currentDate.getDate() + 1) +
      '-' +
      this.newDate.toString()
  );

  ngOnInit() {
    if (this.authService.getLogflag()) {
      this.user = this.authService.getUser();
      console.log('USer:' + JSON.stringify(this.user));
    }
  }

  checkDOB(event: any) {
    let selectedDate: Date = event.value;
    if (selectedDate != null) {
      let currentDate: Date = new Date();
      let maxYear: number = this.currentDate.getFullYear() - 18;
      let selectedYear: number = selectedDate.getFullYear();
      let selectedDates: number = selectedDate.getDate() + 1;
      let selectedMonth: number = selectedDate.getMonth() + 1;

      if (selectedYear == maxYear) {
        if (selectedMonth <= currentDate.getMonth() + 1) {
          if (selectedDates <= currentDate.getDate() + 1) {
            this.validAge = true;
          } else {
            this.validAge = false;
            this.user.dateOfBirth = null;
          }
        } else {
          this.validAge = false;
          this.user.dateOfBirth = null;
        }
      } else if (selectedYear > maxYear) {
        this.validAge = false;
        this.user.dateOfBirth = null;
      } else {
        this.validAge = true;
      }
    }
  }

  userUpdate(invalid: any) {
    if (invalid) {
      return;
    }

    if (this.validAge == false) {
      return;
    }
    this.user.dateOfBirth = null;
    this.userService.editUser(this.user).subscribe((res: any) => {
      const respData = res;
      console.log('data :' + JSON.stringify(respData.data));
      if (respData.success) {
        this.user = respData.data;
      }
      this.router.navigate(['/signup']);
      this.authService.setSessionData(this.user);
    });
  }
}
