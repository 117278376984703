import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { AuthService } from './core/services/auth.service';
import { DesignService } from './core/services/design.service';
import { GeolocationBlockerService } from './core/services/geolocation-blocker.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'CitizeX';

  headerStyle: string;
  headerStyleSubscription: any;

  // loggedInUser used to get cookie details
  private loggedInUser: any;
  public showSideBar = false;

  public getUserLoggedIn(): string {
    return this.authService.getLogflag();
  }

  constructor(
    private authService: AuthService,
    private cookieService: CookieService,
    private router: Router,
    private designService: DesignService,
    private geolocationBlockerService: GeolocationBlockerService
  ) {
    this.headerStyle = designService.headerStyle;
    this.headerStyleSubscription =
      this.designService.headerStyleChange.subscribe((value) => {
        this.headerStyle = value;
      });

    // Check cookie exists or not in application (cookie will be stored, If User pressed 'Remember me' checkbox, while logging in and closed the keeku without logging out )
    if (cookieService.get('keekuUser')) {
      this.loggedInUser = this.cookieService.get('logUserDetails'); // getting the stored user from cookie.
      this.authService.SetLogFlag(); // setting the session data.
      this.authService.setSessionData(JSON.parse(this.loggedInUser));
      this.router.navigateByUrl('/homepage', { replaceUrl: true }); // routing to home page.
    }
  }

  ngOnInit(): void {}
}
