import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '../../core/models/user.model';
import { UserService } from '../../core/services/user.service';
import { DatePickerComponent } from '@syncfusion/ej2-angular-calendars';
import { NgForm } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { DesignService } from '../../core/services/design.service';
import { AuthService } from '../../core/services/auth.service';
import { LoaderService } from '../../core/loader.service';

declare var $: any;
declare var Veriff: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  // user object that contains all the user data
  user: User = new User();
  // Date value
  @ViewChild('DOB') public DOB: DatePickerComponent | undefined;
  public date: Object = new Date();
  public currentDate: Date = new Date();
  public newDate: Number = this.currentDate.getFullYear() - 18;
  public maxDate: Date = new Date(
    this.currentDate.getMonth() +
      1 +
      '-' +
      (this.currentDate.getDate() + 1) +
      '-' +
      this.newDate.toString()
  );

  // activation code array
  actCodeArr: string[] = new Array(6);
  otpCodeArr: string[] = ['', '', '', ''];
  toDos: string[] = [];
  otp: string = '';
  userId: any;
  // error message from response
  errMsg: string[] = [];
  veriffErrMsg: string[] = [];
  invalidPhone = false;
  // flag denoting invalid sign up state.
  invalidSignUp: boolean = false;

  // flag denoting step counter for navigation
  step: number = 1;

  // flag denoting that if username exists or not
  userExists: boolean = false;

  // flag denoting that if phone number exists or not
  phoneExist: boolean = false;

  // flag denoting wrong activation code.
  incorrectActCode: boolean = false;

  // flag denoting match between password and confirm password input fields
  match: boolean = false;

  // flag denoting all fields empty
  empty: boolean = false;

  // flag denoting activation code sent again
  activationsent: boolean = false;

  // flag denoting loading gif active or inactive
  public loading = false;

  // flag dentoting sign up step
  public signupStep: number = 0;

  // flag dentoting terms and condition accepted or not
  public termsAndConditionFlag = false;

  logflag: boolean;
  navigation: any;
  textVerified = false;
  firstClick: boolean = false;
  invalidOTP: boolean = false;
  otpSent = false;
  otpResent = false;
  passwordMeter = false;

  // check user id fully verified or not
  fullyVerified = false;
  existPhoneNumber = false;
  validAge: boolean = true;
  veriffVerified = false;
  veriffStatus: string = '';
  veriffSessionId: any;

  public countryCode: string = 'us';
  public phoneNumber: string = '';
  errorMsg = null;
  accountSetUp = 0;
  verifyIdentity = 70;

  constructor(
    private designService: DesignService,
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private loaderService: LoaderService,
    private title: Title,
    private meta: Meta,
    private datePipe: DatePipe
  ) {
    if (!this.authService.getLogflag()) {
      this.designService.setHeaderStyle('auth'); // Set Header Style to auth
      this.logflag = false;
    } else {
      this.logflag = true;
      this.designService.setHeaderStyle('profile'); // Set Header Style to profile
    }
    this.navigation = this.router.getCurrentNavigation();
    this.title.setTitle('CitizeX');
    let description =
      'CitizeX is social networking service based on United states politicians where all levels of government politicians can interact with message known as "Post a Review." Verified politicians can give review and response, but unregistered politicians can only read them.';
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.removeTag('name= "keywords"');
  }

  ngOnInit() {
    if (this.authService.getLogflag()) {
      this.user = this.authService.getUser();
      console.log('USer:' + JSON.stringify(this.user));

      const params = new HttpParams().set(
        'userId',
        this.user.userId.toString()
      );
      this.userService.getUserDetail(params).subscribe((res) => {
        const respData = res;
        if (respData.success == 0) {
          this.user.veriffFlag = respData.data.veriffFlag;
          this.veriffVerified = respData.data.veriffFlag;
          this.veriffStatus = respData.data.veriffStatus;

          if (this.user.textVerified && this.veriffVerified) {
            this.user.verified = true;
            this.verifyIdentity = 90;
          } else if (this.user.textVerified || this.veriffVerified) {
            this.user.verified = false;
            this.verifyIdentity = 80; // One of them is true
          } else {
            this.user.verified = false;
            this.verifyIdentity = 70; // Neither true
          }
          this.authService.setSessionData(this.user);
          this.designService.sendClickEvent();
          if (respData.data.showCongratsScreen && this.user.verified) {
            $('#congratsModal').modal('show');
          }
        }
      });

      this.textVerified = this.user.textVerified;
      this.veriffVerified = this.user.veriffFlag;
      this.step = 3;
    }
  }

  previous() {
    this.user.password = '';
    this.user.passwordconf = '';
    this.actCodeArr = ['', '', '', '', '', ''];
    this.activationsent = false;
    this.incorrectActCode = false;
    this.firstClick = true;
    if (this.step == 4) {
      this.step = 2;
      this.accountSetUp = 30;
    }
    if (this.step != 1) {
      this.step = this.step - 1;
      this.accountSetUp =
        this.step === 1 ? 0 : this.step === 2 ? 30 : this.step === 4 ? 50 : 0;
    }
    if (this.step == 1) {
      this.firstClick = false;
      this.accountSetUp = 0;
    }
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }

  // This method is used for adding the data from first sign up page
  // sets the user object values and passes it to the service function
  signUp(invalid: any) {
    if (invalid || this.phoneExist) {
      return;
    }
    if (this.user.userId != undefined && !this.user.active) {
      //this.resendActivationCode();
    }
    if (this.validAge == false) {
      return;
    }
    this.loading = true;
    this.firstClick = true;
    this.user.signupStep = this.step;
    let email = this.user.email.toLowerCase();
    this.user.email = email;
    this.user.userName = email;
    // this.user.dateOfBirth = this.datePipe.transform(this.user.dateOfBirth, 'MM-dd-yyyy');
    this.user.dateOfBirth = null;
    console.log('date of birth:' + this.user.dateOfBirth);
    if (this.user.phoneNumber != null) {
      this.user.phoneNumber = this.phoneNumber.toString();
    }
    this.userService.signUp(this.user).subscribe((res: any) => {
      const respData = res;
      console.log('data :' + JSON.stringify(respData.data));
      switch (respData.success) {
        case 0: {
          this.errMsg = [];
          this.invalidSignUp = false;
          this.user = respData.data;
          this.userId = this.user.userId.toString();
          this.step = 2;
          this.accountSetUp = 30;
          break;
        }
        case 1: {
          this.invalidSignUp = true;
          this.firstClick = false;
          this.errMsg = respData.message;
          break;
        }
        case 2: {
          this.step = respData.data.signupStep + 1;
          this.user = respData.data;
          return;
        }
      }
      this.loading = false;
      this.activationsent = false;
    });
  }

  back() {
    this.step = this.step - 1;
    this.accountSetUp =
      this.step === 1 ? 0 : this.step === 2 ? 30 : this.step === 4 ? 50 : 0;
  }

  // This method verifies the activation code and returns invalid state when wrong.
  // it takes userId of the current session and the activation code entered by the user and passes it to the service method.
  activationCodeVerify(invalid: any) {
    if (invalid) {
      return;
    }

    this.user.activationCode = this.actCodeArr.join('');

    const params = new HttpParams()
      .set('userId', this.user.userId.toString())
      .set('activationCode', this.user.activationCode);

    this.userService.activationCodeVerify(params).subscribe((res) => {
      const respData = res;
      if (respData.success == 1) {
        this.incorrectActCode = true;
        this.user.activationCode = '';
        return;
      } else {
        this.incorrectActCode = false;
        this.accountSetUp = 50;
        this.step = 4;
      }
    });
  }

  //  this method is called when clicking the resend button in activation page.
  //  it takes userId of the current session passes it to the service method.
  resendActivationCode(form: NgForm) {
    form.resetForm();
    const params = new HttpParams().set('userId', this.user.userId.toString());
    this.userService.resendActivationCode(params).subscribe((res) => {
      const respData = res;
      if (respData.success == 1) {
        this.invalidSignUp = true;
      } else {
        this.invalidSignUp = false;
        this.activationsent = true;
      }
    });
    return;
  }

  // This method is called on the username input focusout, it checks the username if it already exists and returns invalid if found existing username.
  // it takes the entered username and passes it to the service method.
  checkExistingUsername() {
    const params = new HttpParams().set('userName', this.user.userName);
    this.userService.checkExistingUsername(params).subscribe((res) => {
      const respData = res;
      if (respData.success == 1) {
        this.invalidSignUp = true;
        this.userExists = true;
        return;
      } else {
        this.invalidSignUp = false;
        this.userExists = false;
      }
    });
  }

  // This method is called on the username input focusout, it checks the username if it already exists and returns invalid if found existing username.
  // it takes the entered username and passes it to the service method.
  checkExistingPhoneNumber(phoneNumber: string) {
    const params = new HttpParams().set('phoneNumber', phoneNumber);
    this.userService.checkExistingPhoneNumber(params).subscribe((res) => {
      const respData = res;
      if (respData.success == 1) {
        this.phoneExist = true;
        return;
      } else {
        this.phoneExist = false;
      }
    });
  }

  //  This method checks whether the entered and re-entered password matches or not.
  //  on unmatch it fires a validations.
  confirmPassword() {
    if (this.user.password == this.user.passwordconf) {
      this.match = true;
    } else {
      this.match = false;
    }
  }

  // This method takes the username and password set by user and passes it to the service method.
  // it activates the account after which, user can login.
  activateProfile(invalid: any) {
    // checking if password and confirm password match
    this.confirmPassword();
    if (this.match == false) {
      invalid = true;
    }

    if (invalid) {
      return;
    }
    // let userName = this.user.userName.toLowerCase();
    // this.user.userName = userName;
    this.user.signupStep = this.step;
    this.userService.activateProfile(this.user).subscribe((res: any) => {
      const respData = res;
      if (respData.success == 1) {
        this.invalidSignUp = true;
        return;
      } else {
        this.invalidSignUp = false;
        this.autoSignIn();
      }
    });
  }

  keytab(myIndex: any, key: any, event: any) {
    if (event === 'keyup' && (key.keyCode === 8 || key.keyCode === 46)) {
      key.target.value = '';
      if (myIndex > 0) {
        myIndex = myIndex - 1;
        const myIndex2 = 'activationCode' + myIndex;
        const element = document.getElementById(myIndex2);
        if (element) {
          (element as HTMLInputElement).focus();
        }
      }
    }
    if (event === 'input' && myIndex < 5 && key.target.value !== '') {
      if (key.data in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) {
        myIndex = myIndex + 1;
        const myIndex2 = 'activationCode' + myIndex;
        const element = document.getElementById(myIndex2);
        if (element) {
          (element as HTMLInputElement).focus();
        }
      } else {
        key.target.value = '';
      }
    }
  }

  keytabOTP(myIndex: any, key: any, event: any) {
    if (event === 'keyup' && (key.keyCode === 8 || key.keyCode === 46)) {
      key.target.value = '';
      if (myIndex > 0) {
        myIndex = myIndex - 1;
        const myIndex2 = 'otpCode' + myIndex;
        const element = document.getElementById(myIndex2);
        if (element) {
          (element as HTMLInputElement).focus();
        }
      }
    }
    if (event === 'input' && myIndex < 3 && key.target.value !== '') {
      if (key.data in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) {
        myIndex = myIndex + 1;
        const myIndex2 = 'otpCode' + myIndex;
        const element = document.getElementById(myIndex2);
        if (element) {
          (element as HTMLInputElement).focus();
        }
      } else {
        key.target.value = '';
      }
    }
  }

  //to show terms and conditions
  onTermAndCondition() {
    $('#termAndCondition').modal('show');
  }

  //to-do on accepting terms and conditions.
  onAcceptTermAndCondition(ans: string) {
    $('#termAndCondition').modal('hide');
    if (ans == 'true') {
      this.termsAndConditionFlag = true;
    } else {
      this.termsAndConditionFlag = false;
    }
  }

  //to show the phone number modal.
  onTextVerification() {
    $('#phoneNumberModal').modal('show');
  }

  onVeriffVerification() {
    $('#veriffModal').modal('show');
    console.log('veriff show');
    const veriff = Veriff({
      host: 'https://stationapi.veriff.com',
      apiKey: '836b96b7-c19a-48c0-a514-2ac42f77ed5e',
      //apiKey: '79d32573-88ce-4964-a446-d29cf7a19988',
      parentId: 'veriff-root',
      onSession: (err: any, response: any) => {
        console.log('veriff data : ' + response);
        console.log('veriff data : ' + JSON.stringify(response));
        (window as any).veriffSDK.createVeriffFrame({
          url: response.verification.url,
          onEvent: function (msg: string) {
            console.log(msg);
            if (msg == 'FINISHED') {
              console.log('verificaion finished');
              window.location.reload();
            }
          },
        });
        $('#veriffModal').modal('hide');
        this.veriffSessionId = response.verification.id;
        const params = new HttpParams()
          .set('userId', this.user.userId.toString())
          .set('sessionId', response.verification.id);
        this.userService.veriffDetails(params).subscribe((res) => {
          console.log('veriff api response:' + JSON.stringify(res));
          const respData = res;
          if (respData.success == 1) {
            this.veriffVerified = false;
            this.veriffErrMsg = respData.message;
          } else {
            const params1 = new HttpParams().set(
              'userId',
              this.user.userId.toString()
            );
            this.userService.getUserDetail(params1).subscribe((res) => {
              const respData = res;
              if (respData.success == 0) {
                if (
                  respData.data.showCongratsScreen &&
                  respData.data.verified
                ) {
                  $('#congratsModal').modal('show');
                }
              }
            });
          }
        });
        if (this.authService.getLogflag()) {
          this.veriffVerified = false;
          this.user = this.authService.getUser();
          this.user.verified = false;
          this.user.veriffFlag = false;
          this.authService.setSessionData(this.user);
          this.designService.sendClickEvent();
        }
      },
    });
    veriff.setParams({
      person: {
        givenName: this.user.firstName,
        lastName: this.user.lastName,
      },
      vendorData: this.user.userId.toString(),
    });
    veriff.mount({});
  }

  //to send OTP
  sendOTP(invalid: any) {
    if (invalid) {
      return;
    }
    this.loaderService.disable = true;
    let phoneNumberString: string;
    let prefix: string = '';
    phoneNumberString = prefix + this.phoneNumber.toString();

    const params1 = new HttpParams().set(
      'phoneNumber',
      this.phoneNumber.toString()
    );
    this.userService.checkExistingPhoneNumber(params1).subscribe((res) => {
      const respData = res;
      if (respData.success == 1) {
        this.existPhoneNumber = true;
      } else {
        this.existPhoneNumber = false;
        //API call
        const params = new HttpParams()
          .set('userId', this.user.userId.toString())
          .set('countryCode', this.countryCode)
          .set('phoneNumber', this.phoneNumber.toString());
        this.userService.sendOTP(params).subscribe((res) => {
          const respData = res;
          if (respData.success == 0) {
            this.otpSent = true;

            if (this.authService.getLogflag()) {
              this.textVerified = false;
              this.user = this.authService.getUser();
              this.user.verified = false;
              this.user.textVerified = false;
              this.authService.setSessionData(this.user);
              this.designService.sendClickEvent();
            }
            return;
          } else {
            this.invalidPhone = true;

            if (this.authService.getLogflag()) {
              this.textVerified = false;
              this.user = this.authService.getUser();
              this.user.verified = false;
              this.user.textVerified = false;
              this.authService.setSessionData(this.user);
              this.designService.sendClickEvent();
            }
          }
        });
      }
    });
  }

  // Method to handle form submission
  onSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }
    this.otpVerify();
  }

  //to verify OTP
  otpVerify() {
    this.loaderService.disable = true;

    this.otp = this.otpCodeArr.join('');
    //API call
    const params = new HttpParams()
      .set('userId', this.user.userId.toString())
      .set('otp', this.otp);
    this.userService.verifyOTP(params).subscribe((res) => {
      const respData = res;
      if (respData.success == 1) {
        this.invalidOTP = true;
        return;
      } else {
        if (this.authService.getLogflag()) {
          this.user = this.authService.getUser();
          this.textVerified = true;
          this.user.textVerified = true;
          if (this.textVerified && this.veriffVerified) {
            this.user.verified = true;
            this.verifyIdentity = 90;
          } else if (this.textVerified || this.veriffVerified) {
            this.user.verified = false;
            this.verifyIdentity = 80; // One of them is true
          } else {
            this.user.verified = false;
            this.verifyIdentity = 70; // Neither true
          }
          this.authService.setSessionData(this.user);
          this.designService.sendClickEvent();
        }
        $('#phoneNumberModal').modal('hide');
        this.textVerified = true;
        this.invalidOTP = false;
        this.otpSent = false;
        this.otpCodeArr = new Array(4).fill('');
        this.phoneNumber = '';

        const params = new HttpParams().set(
          'userId',
          this.user.userId.toString()
        );
        this.userService.getUserDetail(params).subscribe((res) => {
          const respData = res;
          if (respData.success == 0) {
            if (respData.data.showCongratsScreen && respData.data.verified) {
              $('#congratsModal').modal('show');
            }
          }
        });
      }
    });
  }

  changePhoneNumber() {
    this.otpSent = false;
  }

  resendOTP(form: NgForm) {
    form.resetForm();
    this.sendOTP(false);
    this.otpResent = true;
  }

  //to-do on skip
  skip() {
    if (this.authService.getLogflag()) {
      this.router.navigateByUrl('/homepage', { replaceUrl: true }); // routing to home page.
    } else {
      this.step = this.step + 1;
      this.fullyVerified = false;
    }
  }

  next() {
    let user = this.authService.getUser();
    if (this.authService.getLogflag()) {
      if (user.verified) {
        if (user.defaultProfileId == null && user.reviewProfileId == false) {
          this.router.navigateByUrl('/profile/search-profile', {
            replaceUrl: true,
          });
        } else {
          this.router.navigateByUrl('/homepage', { replaceUrl: true }); // routing to home page.
        }
      } else {
        this.fullyVerified = true;
      }
    }
  }
  autoSignIn() {
    let tempUser = new User();
    tempUser.email = this.user.userName;
    tempUser.userName = this.user.userName;
    tempUser.email = this.user.userName;
    tempUser.password = this.user.password;
    this.userService.login(tempUser).subscribe(
      (success) => {
        this.loading = false;
        // SetLogflag for Authenticate user
        this.authService.setToken(success.token);
        this.authService.SetLogFlag();
        this.authService.setSessionData(success.data);
        if (success.data.roleProtectedObjectList[0].roleId === 1) {
          // If the logged in user is admin
          this.router.navigate(['/category']);
        } else if (success.data.roleProtectedObjectList[0].roleId === 2) {
          // If the logged in user is not admin
          if (
            success.data.verified === false ||
            success.data.verified === null
          ) {
            // user is not verified.
            $('#signupVerificationModal').modal('show');
            this.designService.setHeaderStyle('profile'); // Set Header Style to profile
            //location.reload();
            this.logflag = true;
            this.step = 3;
          } else if (success.data.verified === true) {
            // user is verified.
            if (
              success.data.defaultProfileId === null &&
              success.data.reviewProfileId == false
            ) {
              this.router.navigate(['/profile/search-profile']);
            } else if (success.data.defaultProfileId !== null) {
              this.router.navigate(['/homepage']);
            }
          }
        }
      },
      (error) => {
        console.log('Error While Login', error);
      }
    );
  }

  checkDOB(event: any) {
    let selectedDate: Date = event.value;
    if (selectedDate != null) {
      let currentDate: Date = new Date();
      let maxYear: number = this.currentDate.getFullYear() - 18;
      let selectedYear: number = selectedDate.getFullYear();
      let selectedDates: number = selectedDate.getDate();
      let selectedMonth: number = selectedDate.getMonth() + 1;

      if (selectedYear == maxYear) {
        if (selectedMonth <= currentDate.getMonth() + 1) {
          if (selectedDates <= currentDate.getDate() + 1) {
            this.validAge = true;
          } else {
            this.validAge = false;
            this.user.dateOfBirth = null;
          }
        } else {
          this.validAge = false;
          this.user.dateOfBirth = null;
        }
      } else if (selectedYear > maxYear) {
        this.validAge = false;
        this.user.dateOfBirth = null;
      } else {
        this.validAge = true;
      }
    }
  }

  onPestCode(event: ClipboardEvent, id: any, len: any) {
    let clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedText = clipboardData.getData('text').trim();
    let myIndex = 0;
    const element = document.getElementById(`${id}${len - 1}`);
    if (element) {
      (element as HTMLInputElement).focus();
    }
    for (var i = 0; i < len; i++) {
      const myIndex2 = id + myIndex;
      const input: any = document.getElementById(myIndex2);

      // pest value in array
      input.value = pastedText.charAt(i);
      const event = new Event('input', {
        bubbles: true,
        cancelable: true,
      });
      input.dispatchEvent(event);

      // pest value in input view
      input.value = pastedText.charAt(i);

      myIndex = myIndex + 1;
    }
  }
  redirectToSearchProfilePage() {
    //this.router.navigate(['/profile/search-profile']);
    $('#congratsModal').modal('hide');
    this.router.navigateByUrl('/profile/search-profile', { replaceUrl: true });
  }

  getUserDetails() {
    const params = new HttpParams().set('userId', this.user.userId.toString());
    this.userService.getUserDetail(params).subscribe((res) => {
      const respData = res;
      if (respData.success == 0) {
        this.user = respData.success.data;
        this.authService.setSessionData(this.user);
      }
    });
  }

  openVerificationModal() {
    if (!this.user.verified && !this.user.veriffFlag) {
      this.onVeriffVerification();
    }
  }

  openTextVerificationModal() {
    if (!this.user.verified && !this.user.textVerified) {
      this.onTextVerification();
    }
  }

  onFocus(event: FocusEvent): void {
    const target = event.target as HTMLInputElement;
    target.select();
  }
}
