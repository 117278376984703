import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ControlContainer, NgForm, NgModelGroup } from '@angular/forms';
import { LoaderService } from '../../../core/loader.service';
import { Country } from '../../../core/models/country.model';
import { Profile } from '../../../core/models/profile.model';
import { ProfileService } from '../../../core/services/profile.service';

@Component({
  selector: 'app-dynamic-fields',
  templateUrl: './dynamic-fields.component.html',
  styleUrls: [
    './dynamic-fields.component.scss',
    '../../../../assets/sass/foundation-themes.scss',
  ],
  viewProviders: [
    { provide: ControlContainer, useExisting: NgModelGroup || NgForm },
  ],
})
export class DynamicFieldsComponent implements OnInit {
  @Input('field')
  field: any;
  @Input('uniqueName')
  uniqueName: string = '';
  @Input('submitForm')
  submitForm!: boolean;
  @Output() informParent = new EventEmitter<any>();

  constructor(
    private profileService: ProfileService,
    private loaderService: LoaderService
  ) {}

  countryFlag: boolean = false;
  country: Country[] = [];
  notMensionList: boolean = false;
  profileList = new Array<Profile>();
  items: any[] = [];
  stateFlag: any;
  validAge: boolean = true;
  currentDate: Date = new Date();
  public newDate: Number = this.currentDate.getFullYear() - 18;
  maxDate: Date | null = null;

  ngOnInit() {
    console.log(this.field.title);
    if (this.field.title === 'state') {
      console.log('country -> called');
      this.countryFlag = true;
      this.getState();
    }
    if (this.field.title === 'Date of Birth') {
      this.maxDate = new Date(
        this.currentDate.getMonth() +
          1 +
          '-' +
          (this.currentDate.getDate() + 1) +
          '-' +
          this.newDate.toString()
      );
    }
    if (
      this.field.title === 'Term Started ' ||
      this.field.title === 'Term Ended' ||
      this.field.title === 'Date Received '
    ) {
      this.maxDate = new Date();
    }
  }

  //get tag-name suggestions.
  getSuggestions(event: any) {
    this.loaderService.disable = true;
    const params = new HttpParams()
      .set('tagNameSubString', event)
      .set('limit', '5'); // getting profile Id from route.
    // APi call
    this.profileService.getSuggestions(params).subscribe((res) => {
      const respData = res;
      if (respData.success == 0) {
        this.items = [];
        this.profileList = respData.data;
        for (let profile of this.profileList) {
          this.items.push({
            name: profile.tagName + ' ',
            img: profile.profileImage,
          });
        }
      }
    });
  }

  getCountry() {
    this.loaderService.disable = true;
    this.field.content = [];
    // APi call
    this.profileService.getOfficeCountry().subscribe((res) => {
      const respData = res;
      if (respData.success == 0) {
        console.log(respData.data);
        this.country = respData.data;
        for (var i = 0; i < this.country.length; i++) {
          this.field.content.push(this.country[i].id);
        }
      }
    });
  }

  getState() {
    this.loaderService.disable = true;
    this.field.content = [];
    // APi call
    const params = new HttpParams().set('countryId', 'USA');
    this.profileService.getOfficeState(params).subscribe((res) => {
      const respData = res;
      if (respData.success == 0) {
        console.log(respData.data);
        this.country = respData.data;
        for (var i = 0; i < this.country.length; i++) {
          this.field.content.push(this.country[i].name);
        }
      }
    });
  }

  onChange(event: any): void {
    console.log('onChange -->' + event);
    if (this.countryFlag) {
      this.informParent.emit(event);
    }
  }

  hasRequiredError(dropDown: any): boolean {
    return dropDown?.errors?.['required'] ?? false;
  }
}
