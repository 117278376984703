import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[datatypeValidation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: dataTypeValidatorDirective,
      multi: true,
    },
  ],
})
export class dataTypeValidatorDirective implements Validator {
  @Input('datatypeValidation') req: string | undefined;

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (this.req === 'Integer' || this.req === 'Double') {
      const isNumber = !isNaN(control.value);
      const isValid = isNumber;
      return isValid ? null : { numberAllow: true };
    }

    return null; // Add a default return statement
  }
}
