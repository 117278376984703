import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss'],
})
export class ThankyouComponent implements OnInit {
  flag: boolean = false;
  content: String = '';
  signupDone: boolean = false;
  constructor(private router: Router) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation != undefined) {
      const navigationState = navigation.extras.state;
      if (navigationState != undefined) {
        this.flag = navigation.extras.state
          ? navigation.extras.state['passwordReset']
          : true;
        if (this.flag == true) {
          this.content =
            'Your new password has been set successfully. Please login with your New Password.';
          this.signupDone = true;
        }
        this.signupDone = navigation.extras.state
          ? navigation.extras.state['signupDone']
          : false;
        if (this.signupDone == false) {
          this.content =
            'Your profile request has been submitted successfully. You will be notified by email address once your identity is verified.';
        }
      }
    }
  }

  ngOnInit() {
    this.redirection(this.router);
  }

  redirection(router: Router) {
    let timeleft = 10;
    const downloadTimer = setInterval(() => {
      const countdownElement = document.getElementById('countdown');
      if (countdownElement) {
        countdownElement.innerHTML =
          'You will be redirected to the login page in ' +
          timeleft +
          ' seconds';
      }
      timeleft -= 1;
      if (timeleft <= 0) {
        router.navigate(['login']);
        clearInterval(downloadTimer);
      }
    }, 1000);
  }
}
