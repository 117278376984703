export class BlockProfileDTO {
  id: number | null;
  userId: number;
  profileId: number;
  blockedProfileId: number;
  active: boolean;
  profileName: string;
  profileImage: string;

  constructor() {
    this.id = null;
    this.userId = 0;
    this.profileId = 0;
    this.blockedProfileId = 0;
    this.active = false;
    this.profileName = '';
    this.profileImage = '';
  }
}
