<!-- footer -->
<!-- <footer class="admin-footer col-md-12 col-sm-12 p-0 m-0" *ngIf="footerStyle !== 'homepage' && footerStyle !== 'profile'">
  <div class="container p-0 h-100">
    <div class="row m-0 p-0 h-100 align-items-center">
      <div class="col text-sm-left text-center">
        <p><a class="title font-14" [routerLink]="['/']">Keeku</a></p>
      </div>
      <-- .col ->
      <div class="col-auto text-sm-right text-center">
        <p>Copyright Keeku. All Rights Reserved - Version {{ version }} - {{ date }}</p>
      </div>
      <-- .col ->
    </div>
  </div>
</footer> -->
<!-- footer Ends -->

<footer class="homepage-footer container-fluid footer-div">
  <div class="align-content-center flex-column footer-div">
    <p class="col-12 font-12 text-center fw-800 mb-1 mt-3">
      <a href="https://citizex.ai/terms-and-conditions" target="_blank"
        >Terms and Conditions</a
      ><span class="ml-2"
        ><a href="https://citizex.ai/privacy-and-policy" target="_blank"
          >Privacy Policy</a
        ></span
      >
    </p>
    <p class="col-12 font-12 text-center">
      Copyright CitizeX. All Rights Reserved - Version {{ version }} -
      {{ date }}
    </p>
  </div>
</footer>
