import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';

import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';

import { AuthRoutingModule } from './auth-routing.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgxMaskModule } from 'ngx-mask';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';

import { SharedModule } from '../shared/shared.module';
import { EditUserComponent } from './edit-user/edit-user.component';
import { PrivacyAndPolicyComponent } from './privacy-and-policy/privacy-and-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

@NgModule({
  declarations: [
    SigninComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    EditUserComponent,
    PrivacyAndPolicyComponent,
    TermsAndConditionsComponent,
    DeleteAccountComponent,
    UnsubscribeComponent,
  ],
  imports: [
    AuthRoutingModule,
    FormsModule,
    DatePickerModule,
    NgxMaskModule.forRoot(),
    PasswordStrengthMeterModule,
    SharedModule,
  ],
  providers: [],
})
export class AuthModule {}
