import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: 'category',
    loadChildren: () =>
      import('./template/template.module').then((m) => m.TemplateModule),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'homepage',
    loadChildren: () =>
      import('./homepage/homepage.module').then((m) => m.HomepageModule),
  },
  {
    path: 'deduplication',
    loadChildren: () =>
      import('./deduplication/deduplication.module').then(
        (m) => m.DeduplicationModule
      ),
  },
  {
    path: 'search',
    loadChildren: () =>
      import('./search/search.module').then((m) => m.SearchModule),
  },
  {
    path: 'spam-reports',
    loadChildren: () =>
      import('./spam-reports/spam-reports.module').then(
        (m) => m.SpamReportsModule
      ),
  },
  {
    path: 'election-details',
    loadChildren: () =>
      import('./election-details/election-details.module').then(
        (m) => m.ElectionDetailsModule
      ),
  },
  {
    path: 'verify-profiles',
    loadChildren: () =>
      import('./verify-profile/verify-profile.module').then(
        (m) => m.VerifyProfileModule
      ),
  },
  {
    path: 'claim-request',
    loadChildren: () =>
      import('./claim-request/claim-request.module').then(
        (m) => m.ClaimRequestModule
      ),
  },
  {
    path: 'matching-profile',
    loadChildren: () =>
      import('./matching-profile/matching-profile.module').then(
        (m) => m.MatchingProfileModule
      ),
  },
  {
    path: 'view-user-profile/:profileId',
    loadChildren: () =>
      import('./view-user-profile/view-user-profile.module').then(
        (m) => m.ViewUserProfileModule
      ),
  },
  {
    path: 'claim-profile/:profileId',
    loadChildren: () =>
      import('./claim-profile/claim-profile.module').then(
        (m) => m.ClaimProfileModule
      ),
  },
  {
    path: '',
    redirectTo: '/homepage',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
