import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    let authService = this.injector.get(AuthService);

    // let currentUser =  JSON.parse(localStorage.getItem('currentUser'));
    // console.log("token : "+authService.getToken());

    if(!request.url.startsWith('https://westus.api.cognitive.microsoft.com')){
      if (authService.getToken()) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${authService.getToken()}`
          },
          withCredentials: true
        });
      }
    }
    return next.handle(request);
  }
}
